import Papa from 'papaparse'
import FileSaver from 'file-saver'
import { apiV3 } from '@/api'
// Organization
export const createOrganization = body => {
  const url = '/organizations/'
  return apiV3.post(url, body)
}

export const getOwnOrganizationsList = (limit = 50, offset = 0) => {
  const url = `/organizations/?limit=${limit}&offset=${offset}`
  return apiV3.get(url)
}

export const getOrganizationById = id => {
  const url = `/organizations/${id}/`
  return apiV3.get(url)
}

export const updateOrganizationById = (id, data) => {
  const url = `/organizations/${id}/`
  return apiV3.patch(url, { ...data })
}

export const updateOrganizationLogo = (id, logo) => {
  const url = `/organizations/${id}/upload-logo/`
  const formData = new FormData()
  formData.append('logo', logo)
  return apiV3.patch(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// Users(members)
export const inviteUserToOrganization = (orgId, body) => {
  const url = `/organizations/${orgId}/invite-user/`
  return apiV3.post(url, body)
}

export const checkInvitations = () => {
  const url = '/organizations/invitations/'
  return apiV3.get(url)
}

export const updateInvitation = (invitationId, status) => {
  const url = `/organizations/invitations/${invitationId}/`
  return apiV3.patch(url, { status })
}

export const getMemberships = (orgId, params) => {
  const query = new URLSearchParams(params).toString()
  const url = `/organizations/${orgId}/members/?${query}`
  return apiV3.get(url)
}

export const getMembershipById = (orgId, membershipId) => {
  const url = `/organizations/${orgId}/members/${membershipId}/`
  return apiV3.get(url)
}

// export const membershipSearch = (orgId, search) => {
//   const url = `/organizations/${orgId}/members/?search=${search}`
//   return apiV3.get(url)
// }

/**
 * @param {Number|String} orgId
 * @param {Number|String} membershipId
 * @param {Object} body
 * @param {Number|String} body.organization_role - roleId, retrieved from organizations
 * @param {0|1|2|3} [body.status] - 0: Invited 1: Active 2: Paused 3: Deactivated
 *
 * @returns {Promise}
 */
export const updateMembership = (orgId, membershipId, body) => {
  const url = `/organizations/${orgId}/members/${membershipId}/`
  return apiV3.patch(url, body)
}

export const bulkDeleteMembership = (orgId, ids) => {
  const url = `/organizations/${orgId}/members/bulk-delete/`
  return apiV3.post(url, { ids })
}

export const exportMembersToCsv = orgId => {
  getMemberships(orgId, { limit: 3000 }).then(({ data }) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const currentDate = new Date()
    const dataForCsv = data.results.map(member => ({
      'Member Id': member.member_id,
      'First Name': member.user.first_name,
      'Last Name': member.user.last_name,
      'Email': member.user.email,
      'Phone': member.user.phone,
      'Biometric Enrolled': member.user.is_biometric_exists,
      [`Created At Date (${timeZone})`]: new Date(member.user.date_joined).toLocaleDateString(),
      [`Created At Time (${timeZone})`]: new Date(member.user.date_joined).toLocaleTimeString(),
      'Created At (ISO UTC)': member.user.date_joined,
    }))
    const blob = new Blob([Papa.unparse(dataForCsv)], { type: 'text/csv;charset=utf-8' })
    FileSaver.saveAs(
      blob,
      `members-${currentDate.toLocaleDateString()}-${currentDate.toLocaleTimeString()}.csv`,
    )
  })
}

// Devices
// TODO: cursor pagination from API not available at the moment to properly handle device profile routing so we using workaround to load all devices right away for now
export const getOrganizationDevicesList = (id, limit = 500, offset = 0) => {
  const url = `/organizations/${id}/devices/?limit=${limit}&offset=${offset}`
  return apiV3.get(url)
}

export const getDeviceProfile = (orgId, deviceId) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/`
  return apiV3.get(url)
}

export const getDeviceProfileExposedData = (orgId, deviceId) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/exposed-data/`
  return apiV3.get(url)
}

export const patchDeviceProfile = (orgId, deviceId, body) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/`
  return apiV3.patch(url, body)
}

export const createDeviceLocationPhoto = (orgId, deviceId, photo) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/location-photos/`
  const formData = new FormData()
  formData.append('photo', photo)
  return apiV3.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const deleteDeviceLocationPhoto = (orgId, deviceId, photoId) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/location-photos/${photoId}/`
  return apiV3.delete(url, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const organizationLeave = orgId => {
  return apiV3.delete(`organizations/${orgId}/leave/`)
}

export const startEnroll = (orgId, deviceId) => {
  return apiV3.post('devices/create-start-enroll/', { organization_id: orgId, device_id: deviceId })
}
