import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/auth/pages/SignIn.vue'),
  },
  {
    path: '/login/code',
    name: 'login.code',
    component: () => import('@/modules/auth/pages/SecureCode.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/modules/auth/pages/Register.vue'),
  },
  {
    path: '/forgot-password-expired',
    name: 'forgot-password-expired',
    component: () => import('@/modules/auth/pages/ForgotPasswordExpired.vue'),
  },
  {
    path: '/resend-invitation-link',
    name: 'resend-invitation-link',
    component: () => import('@/modules/auth/pages/ResendInvitationLink.vue'),
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/modules/auth/pages/Signup.vue'),
  },
  {
    path: '/forgot-password/instruction',
    name: 'forgot-password.instruction',
    component: () => import('@/modules/auth/pages/ForgotPasswordInstruction.vue'),
  },
  {
    path: '/forgot-password/set',
    alias: '/reset-password', // TODO: for now support old url to not introduce breaking change for API
    name: 'forgot-password.set',
    component: () => import('@/modules/auth/pages/ForgotPasswordSet.vue'),
  },

  // Resend activation link after the pilot sign-up flow via kiosk (Keyo OS)
  {
    path: '/expired-link/resend',
    name: 'expired-link.resend',
    component: () => import('@/modules/auth/pages/KioskActivationResend.vue'),
  },
  {
    path: '/expired-link/success',
    name: 'expired-link.success',
    component: () => import('@/modules/auth/pages/KioskActivationResendSuccess.vue'),
  },
  {
    path: '/activate/verify-you',
    name: 'activate.verify',
    component: () => import('@/modules/auth/pages/ActivationVerifyInstruction.vue'),
  },
  {
    path: '/activate/verify-you/edit/:method',
    name: 'activate.verify.edit.method',
    component: () => import('@/modules/auth/pages/EditMethod.vue'),
  },
  {
    path: '/activate/verify-you/edit',
    name: 'activate.verify.edit',
    props: {
      isEdit: true,
    },
    component: () => import('@/modules/auth/pages/ActivationVerifyInstruction.vue'),
  },
  {
    path: '/activate/code',
    name: 'activate.code',
    component: () => import('@/modules/auth/pages/ActivationVerifySet.vue'),
  },
  {
    path: '/activate/email',
    name: 'activate.email',
    component: () => import('@/modules/auth/pages/ActivationEmail.vue'),
  },
  {
    path: '/activate/password',
    name: 'activate.password',
    component: () => import('@/modules/auth/pages/ActivationPassword.vue'),
  },
  {
    path: '/activate/phone',
    name: 'activate.phone',
    component: () => import('@/modules/auth/pages/ActivationPhone.vue'),
    meta: { ignoreAuthProtectionGuard: true },
  },
  {
    path: '/activate/phone/code',
    name: 'activate.phone.code',
    component: () => import('@/modules/auth/pages/ActivationPhoneCode.vue'),
    meta: { ignoreAuthProtectionGuard: true },
  },
  {
    path: '/activate/full-name',
    name: 'activate.full-name',
    component: () => import('@/modules/auth/pages/FullName.vue'),
    meta: { ignoreAuthProtectionGuard: true },
  },
]
export default routes
