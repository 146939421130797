import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { useOrganizationsStore, usePersonalStore } from '@/store'
import * as storeUtils from '@/store/utils'

import { clearTokens, getRefreshJwt } from '@/api'
import { jwt } from '@/api/utils'
import toast from '@/libs/toast'

import { MOBILE_HASH_TOGGLE } from '@/constants/sidebar'
import { isAuthRoute, routes } from '@/pages/routes'

import { MEMBERSHIP_STATUS } from '@/store/organizations'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    savedPosition: { top: number } | null,
  ) {
    // prevent scroll to top when sidebar toggles
    if ([to.hash, from.hash].includes(MOBILE_HASH_TOGGLE)) {
      return {}
    }
    if (to.hash) return { el: to.hash }

    // prevent scroll to top when modal toggles
    if (to.query.modal || from.query.modal) {
      return {}
    }
    if (to.path === from.path) {
      return { top: 0, behavior: 'smooth' }
    }
    return savedPosition ?? { top: 0 }
  },
})

async function getOrg(orgId: string) {
  const orgs = useOrganizationsStore()
  const org = orgs.items.get(orgId)
  if (org) {
    return org
  } else {
    await orgs.getList()
    return orgs.items.get(orgId)
  }
}

async function getOrgPermissions(orgId: string) {
  try {
    const org = await getOrg(orgId)
    return {
      hasOrgAccess:
        org.member.role !== 'User' && org.member.status.value === MEMBERSHIP_STATUS.ACTIVE,
      isOrgOwner: org.member.id === org.owner.member_id,
    }
  } catch (e) {
    return {
      hasOrgAccess: false,
      isOrgOwner: false,
    }
  }
}

async function canAccessRoute(to: RouteLocationNormalized) {
  if (!to.meta.requiresOrgAccess && !to.meta.requiresOrgOwner) {
    return true
  }

  const permissions = await getOrgPermissions(to.params.id as string)

  if (to.meta.requiresOrgAccess && !permissions.hasOrgAccess) {
    return false
  }

  if (to.meta.requiresOrgOwner && !permissions.isOrgOwner) {
    return false
  }

  return true
}

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const personal = usePersonalStore()
    const orgStore = useOrganizationsStore()

    if (isAuthRoute(to)) {
      // NOTE[id=logout-router-guard] handles logout - always clear token
      clearTokens()
      next()
      return
    }
    if (to.meta.isOpen) {
      next()
      return
    }

    const refreshToken = getRefreshJwt()
    if (jwt.isExpired(refreshToken)) {
      toast.show(refreshToken ? 'Session expired' : 'Please login')
      next({ name: 'login', query: { redirect: to.fullPath } })
      return
    }

    const handleProfileFetchError = () => {
      // force logout if profile fetch fails
      router.push({ name: 'login', query: { redirect: to.fullPath } })
    }

    if (to.name !== from.name) {
      // TODO: temporary solution to always fetch up to date mandatory data for signed-in users

      // If profile is not set then fetch it synchronously to block navigation until it's set
      // If profile is set then just fetch it asynchronously to keep it up to date
      personal.isProfileSet
        ? personal.profileFetch().catch(handleProfileFetchError)
        : await personal.profileFetch().catch(handleProfileFetchError)

      orgStore.getList()
    }

    // Check if the current route is the home page
    if (to.fullPath === '/') {
      next({ name: 'personal' })
      return
    }

    const isNavigationAllowed = await canAccessRoute(to)
    if (!isNavigationAllowed) {
      next({ name: 'not-found' })
      return
    }

    next()
    return
  },
)

router.afterEach(async (to: RouteLocationNormalized) => {
  if (isAuthRoute(to)) {
    storeUtils.resetAll()
  }
})

export default router
