import { defineStore } from 'pinia'
import toast from '@/libs/toast'
import { i18n } from '@keyo/core/i18n'

import account from '../api'
import { checkInvitations, updateInvitation } from '@/api/organization'
import { useOrganizationsStore } from '@/store/organizations'
import useLanguage from '@/composables/useLanguage'
import { formatDob } from '@/utils'
import type { SignInMethod } from '@/api/auth.ts'
import type { Code } from '@/types/code.ts'
import { getMethodI18nValue } from '@/modules/account/utils'
import type { AvailableLocales } from '@keyo/core/i18n'

const profileCompleteFields = ['first_name', 'last_name'] as const

export type UI_SETTINGS_KEYS =
  | 'hide_unverified_methods_banner'
  | 'hide_empty_methods_banner'
  | 'user_locale'

export interface Profile {
  id: number
  photo: string
  first_name: string
  last_name: string
  display_name: string
  date_of_birth: string | null
  email: string
  phone: string
  country: string
  date_joined: string
  permissions: string[]
  owner_org_ids: string[]
  org_limit: number
  is_biometric_exists: boolean
  is_confirmed_email: boolean
  is_confirmed_phone: boolean
  preferable_mfa_method: 'email' | 'phone'
  ui_settings: Partial<Record<UI_SETTINGS_KEYS, unknown>>
  biometric_policy: string | null
  privacy_policy: string | null
  terms_of_use: string | null
  status: InvitationStatus
  last_enrollment_date: string | null
  last_identification_date: string | null
  last_deleted_biometric_date: string | null
  language: string
}
type InvitationStatus =
  | { value: 0; name: 'SENT' }
  | { value: 1; name: 'ACCEPTED' }
  | { value: 2; name: 'CANCELLED' }

interface Invitation {
  id: number
  status: InvitationStatus
  creation_date: string
  update_date: string
}

export interface CodeBody {
  code: Code
}

export interface DeleteByMethodBody extends CodeBody {
  client_id: string
}

export interface PhoneSetBody extends CodeBody {
  phone: string
  client_id: string
}

export interface EmailSetBody extends CodeBody {
  email: string
  client_id: string
}

export interface ChangePhoneBody {
  new_phone?: string
  code_from_old_phone?: Code
  code_from_new_phone?: Code
  captcha_token?: string
  client_id_from_old_phone?: string
  client_id_from_new_phone?: string
}

export interface ChangeEmailBody {
  new_email?: string
  code?: string
  code_from_new_email?: Code
  code_from_old_email?: Code
  client_id_from_old_email?: string
  client_id_from_new_email?: string
}

export type ProfilePhoto = File | string

export interface MfaPhoneRequestBody {
  phone: string
  captcha_token: string
}

export interface VerifyMethodBody extends CodeBody {
  method: string
  client_id: string
}

// workaround for the API as enum allowing only 'en-us' for English and 'es' for Spanish 🤷‍♂️
const mapLangToApiLang = (lang: AvailableLocales) => {
  switch (true) {
    case lang.startsWith('en'):
      return 'en-us'
    case lang.startsWith('es'):
      return 'es'
    case lang.startsWith('fr'):
      return 'fr'
  }
}

const toLocaleString = (date: string | null, locale: AvailableLocales) =>
  date
    ? new Date(date).toLocaleDateString(locale, {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
      })
    : undefined

export const usePersonalStore = defineStore('personal', {
  state: () => ({
    profile: {} as Profile,
    invitations: [],
    unansweredInvitations: [],
    invitationsTotal: 0,
  }),

  actions: {
    profileSet(profile: Profile) {
      this.profile = profile
    },
    async profileFetch() {
      const resp = await account.profileGet()
      this.profileSet(resp.data)
    },

    async profileUpdate(form: Partial<Profile>) {
      if (form.date_of_birth === '') {
        form.date_of_birth = null
      }
      if (form.language) {
        form.language = mapLangToApiLang(form.language as AvailableLocales)
      }
      const resp = await account.profileUpdate(form)
      this.profileSet({ ...this.profile, ...resp.data })
    },

    async profileDelete(form: DeleteByMethodBody) {
      try {
        await account.profileDelete(form)
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async palmsDelete(form: DeleteByMethodBody) {
      try {
        await account.palmDelete(form)
        await this.profileFetch()
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async changePassword(form: CodeBody) {
      await account.changePassword(form)
    },
    async setEmail({ code, email, client_id }: EmailSetBody) {
      const resp = await account.setEmail({
        code,
        email,
        client_id,
      })

      this.profileSet({ ...this.profile, ...resp.data.user })
    },
    async setPhone({ code, phone, client_id }: PhoneSetBody) {
      const resp = await account.setPhone({
        code,
        phone,
        client_id,
      })

      this.profileSet({ ...this.profile, ...resp.data.user })
    },
    async changeEmail(form: ChangeEmailBody) {
      try {
        const resp = await account.changeEmail(form)
        this.profileSet({ ...this.profile, ...resp.data.user })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async changePhone(form: ChangePhoneBody) {
      try {
        const resp = await account.changePhone(form)
        this.profileSet({ ...this.profile, ...resp.data.user })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async verifyMethod(form: VerifyMethodBody) {
      try {
        await account.verifyMethod(form)
        await this.profileFetch()
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async profileUpdatePhoto(photo: ProfilePhoto) {
      try {
        const resp = await account.profileSetPhoto(photo)
        this.profileSet({ ...this.profile, ...resp.data })
        toast.show(() => i18n.global.t('modules.personal.store.uploadPhotoSuccess'), 'success')
      } catch (e) {
        console.error(e)
        toast.show(() => i18n.global.t('modules.personal.store.uploadPhotoError'), 'error')
      }
    },

    async profileDeletePhoto() {
      try {
        const resp = await account.profileSetPhoto('')
        this.profileSet({ ...this.profile, ...resp.data })
        toast.show(() => i18n.global.t('common.photoDeleted'), 'success')
      } catch (e) {
        console.error(e)
        toast.show(() => i18n.global.t('modules.personal.store.deletePhotoError'), 'error')
      }
    },
    async getInvitations() {
      try {
        const resp = await checkInvitations()
        this.invitations = resp.data.results
        this.invitationsTotal = resp.data.count
        this.unansweredInvitations = resp.data.results.filter(
          (item: Invitation) => item?.status?.value === 0, // invitation SENT
        )
      } catch (e) {
        console.error(e)
        toast.show('Failed to fetch invitations', 'error')
      }
    },
    async patchInvitations(invitation_id: number, status: number) {
      try {
        await updateInvitation(invitation_id, status)
        const orgs = useOrganizationsStore()
        orgs.getList()
        this.getInvitations()
      } catch (e) {
        console.error(e)
        toast.show('Failed to respond for invitation', 'error')
      }
    },
  },

  getters: {
    firstName(state) {
      return state.profile?.first_name || '-'
    },
    lastName(state) {
      return state.profile?.last_name || '-'
    },
    displayName(state) {
      return state.profile?.display_name || '-'
    },
    someName(state) {
      return (
        state.profile?.display_name ||
        state.profile?.first_name ||
        state.profile?.email?.split('@')[0]
      )
    },
    email(state) {
      return state.profile?.email || '-'
    },
    phone(state) {
      return state.profile?.phone || '-'
    },
    country(state) {
      return state.profile?.country || '-'
    },
    isProfileSet(state) {
      return !!Object.keys(state.profile).length
    },
    initials(state) {
      const inits = `${state.profile?.first_name?.[0] || ''}${state.profile?.last_name?.[0] || ''}`
      return inits || state.profile?.email?.[0] || ''
    },
    fullName(state) {
      const fname = state.profile?.first_name ? state.profile?.first_name : ''
      const lname = state.profile?.last_name ? state.profile?.last_name : ''
      return (fname + ' ' + lname).trim()
    },
    dob(state) {
      return formatDob(state.profile.date_of_birth)
    },
    id(state) {
      return state.profile.id
    },
    photo(state) {
      return state.profile.photo
    },
    isProfileComplete(state) {
      for (let i = 0; i < profileCompleteFields.length; i++) {
        if (!state.profile[profileCompleteFields[i]]) return false
      }
      return true
    },
    isEnrolled() {
      return false
    },
    isOwner(state) {
      return !!state.profile?.owner_org_ids?.length
    },
    hasPermCreateOrganization(state) {
      return state.profile?.permissions?.includes('add_organization')
    },

    canCreateOrganization(state): boolean {
      return state.profile?.owner_org_ids?.length < this.maxOrgs && this.hasPermCreateOrganization
    },
    maxOrgs(state) {
      return state.profile?.org_limit
    },
    acceptedAt(state) {
      const [, month, day, year] = new Date(state.profile.date_joined).toDateString().split(' ')
      return `${month} ${day}, ${year}`
    },
    lastEnrollmentDate(state) {
      const { currentLocale } = useLanguage()
      return toLocaleString(state.profile?.last_enrollment_date, currentLocale.value)
    },
    lastIdentificationDate(state) {
      const { currentLocale } = useLanguage()
      return toLocaleString(state.profile?.last_identification_date, currentLocale.value)
    },
    lastDeletedBiometricDate(state) {
      const { currentLocale } = useLanguage()
      return toLocaleString(state.profile?.last_deleted_biometric_date, currentLocale.value)
    },
    preferableMfaMethod(state): SignInMethod {
      return state.profile?.preferable_mfa_method ?? 'email'
    },
    preferableMfaMethodName(state) {
      return getMethodI18nValue(state.profile?.preferable_mfa_method)
    },
    preferableMfaMethodValue(state): string {
      return (
        state.profile?.preferable_mfa_method === 'phone'
          ? state.profile?.phone
          : state.profile?.email
      ) as string
    },
  },
})
