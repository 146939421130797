<script lang="ts" setup>
import { useRoute } from 'vue-router'

import type { Breadcrumb } from '@/components/Breadcrumbs/Breadcrumbs.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import { Icon } from '@keyo/ui'

import LanguageSwitcherMenu from './LanguageSwitcherMenu.vue'
import ProfileDropdown from './ProfileDropdown.vue'

import { MOBILE_HASH_TOGGLE } from '@/constants/sidebar'

import { computed } from 'vue'

const route = useRoute()

const isPersonalActive = computed(() => /^\/personal/.test(route.fullPath))

const homeRoute = computed(() =>
  isPersonalActive.value ? { name: 'personal' } : { name: 'organization' },
)
const isHomeRoute = computed(
  () => 'personal.overview' === route.name || String(route.name).startsWith('org'),
)
const breadcrumbs = computed(() => route.meta.breadcrumbs as Breadcrumb[])
const backRoute = computed(() => {
  if (!breadcrumbs.value) return
  return breadcrumbs.value[breadcrumbs.value.length - 2]?.route
})
const toggleRoute = computed(() => (route.hash === MOBILE_HASH_TOGGLE ? '' : MOBILE_HASH_TOGGLE))
</script>

<template>
  <header class="header">
    <Breadcrumbs class="breadcrumbs" :home="homeRoute" :breadcrumbs="breadcrumbs" />
    <LanguageSwitcherMenu />
    <ProfileDropdown />
  </header>
  <header class="header-mobile">
    <router-link v-if="backRoute" :to="{ name: backRoute }" class="link-back link">
      <Icon name="left-3" />
    </router-link>
    <router-link v-else :to="{ hash: toggleRoute }" class="link-menu link">
      <Icon name="hamburger-menu" />
    </router-link>
    <span class="text-label-l">{{
      $te(route.meta.title as string) ? $t(route.meta.title as string) : ''
    }}</span>
    <div class="lang-user">
      <LanguageSwitcherMenu v-if="isHomeRoute" />
      <ProfileDropdown />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  display: none;
}

.header-mobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  background-color: var(--clr-bg-primary);
  border-bottom: solid 1px var(--clr-border-divider);

  .link {
    display: grid;
    place-content: center;
    width: 4rem;
    height: 4rem;
    color: var(--clr-icon-subtle);
  }

  span {
    place-self: center;
    color: var(--clr-txt-default);
  }

  .link-back {
    // Applying translateZ(0) to overcome a Safari/iOS-specific rendering issue
    // where the "link-back" element sporadically disappears.
    // Applying translateZ(0) to trigger GPU acceleration and fix visibility issue on Safari/iOS
    transform: translateZ(0);
  }

  .lang-user {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.75rem;
  }
}

@media screen and (min-width: $mobile) {
  .header-mobile {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: var(--header-height);
    max-width: var(--max-page-width);
    margin: 0 auto;
    padding: 0.5rem 2rem 0;
    box-shadow: var(--header-shadow);
    background-color: var(--clr-bg-body);
    min-width: 0; // for text overflow

    > * {
      margin-left: 1rem;
    }

    .breadcrumbs {
      margin: 0 auto 0 0;
    }
  }
}
</style>
