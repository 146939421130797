import type { ValuesType } from 'utility-types'

// let's keep it here for now, until we have another usage for it
type NumberString = `${number}`
type DecimalString = `${number}.${number}`

export const TRANSACTION_TYPE = {
  SALE: 0,
  REFUND: 1,
  VOID: 2,
} as const
export type TransactionType = ValuesType<typeof TRANSACTION_TYPE>

type TransactionBase = {
  un_transaction_id: string
  auth_code: string
  date_time: string
  requested_amount: number
  tax_amount: number
  total: number
}

export type TransactionInput = TransactionBase & {
  transaction_type: TransactionType
}

// https://api-v3.rookie.keyo.co/api/v3/docs/#operation/organizations_members_cards_transactions_list
export type TransactionResult = TransactionBase & {
  id: number
  transaction_type: {
    name: string
    value: TransactionType
  }
  vendor_name?: string
}

export type TransactionResults = {
  count: number
  next: string
  previous: string
  results: TransactionResult[]
} | null

/**
 * Format the currency string value for display
 * @param value in API format (e.g. '1200' meaning 1200 cents)
 * @returns formatted value (e.g. '12.00')
 */
const formatCurrency = (value: NumberString | number): DecimalString => {
  const total = Number(value) / 100
  return total.toFixed(2) as DecimalString
}

export const prepareTransaction = (item: TransactionResult) => ({
  ...item,
  requested_amount: Number(item.requested_amount),
  tax_amount: Number(item.tax_amount),
  total: Number(item.total),
  totalDisplay: formatCurrency(item.total),
  dateTimeDisplay: new Date(item.date_time).toLocaleString(),
})

export type Transaction = ReturnType<typeof prepareTransaction>
