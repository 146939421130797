<script lang="ts" setup>
import { useAuthN } from '@/composables'
import { usePersonalStore } from '@/store'
import { Avatar, Icon } from '@keyo/ui'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const authN = useAuthN()
const personal = usePersonalStore()

const isOpen = ref(false)
const dropdown = ref<HTMLElement>()
onClickOutside(dropdown, () => {
  isOpen.value = false
})
onKeyStroke('Escape', e => {
  if (isOpen.value) {
    e.preventDefault()
    isOpen.value = false
  }
})

const route = useRoute()
const isPersonalActive = computed(() => /^\/personal/.test(route.fullPath))
function toggleDropdown() {
  isOpen.value = !isOpen.value
}

const role = computed(() =>
  isPersonalActive.value ? t('layouts.personal') : t('layouts.administrator'),
)
</script>

<template>
  <div class="user__block">
    <button ref="dropdown" class="text__group" @click="toggleDropdown">
      <Avatar :src="personal.photo" :initials="personal.initials" class="avatar" />
      <div class="user__info">
        <h4 class="user__name text-label-s">{{ personal.someName }}</h4>
        <p class="user__type text-label-xs">{{ role }}</p>
      </div>
      <Icon name="arrow-down" class="user__chevron" :class="{ open: isOpen }" />
    </button>
    <div class="dropdown__menu" :class="{ open: isOpen }">
      <div class="menu__item">
        <Avatar :src="personal.photo" :initials="personal.initials" class="avatar" />
        <div class="user__info">
          <h4 class="text-label-s">{{ personal.fullName }}</h4>
          <p class="text-label-xs">{{ personal.email }}</p>
        </div>
      </div>
      <router-link
        :to="{ name: 'personal.profile' }"
        class="menu__item menu__item__profile"
        @click="toggleDropdown"
      >
        <div class="icon-holder">
          <Icon name="user-filled" />
        </div>
        <span class="text-label-xs">{{ $t('layouts.profileSettings') }}</span>
      </router-link>
      <button class="menu__item menu__item__logout" @click="authN.signOut(), toggleDropdown()">
        <div class="icon-holder">
          <Icon name="logout-filled" />
        </div>
        <span class="text-label-xs">{{ $t('layouts.logOut') }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user__block {
  position: relative;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-border-default);
  background-color: var(--color-blue-200);
  color: var(--color-blue-500);
}
.user__info {
  overflow: hidden;

  h4 {
    font-weight: 600;
    color: var(--clr-txt-default);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    color: var(--clr-txt-subtle);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.text__group {
  .user__info {
    display: none;
  }

  .user__chevron {
    display: none;
  }
}

.dropdown__menu {
  display: none;
  position: absolute;
  top: 3rem;
  box-shadow: 0px 0px 12px -3px rgba(66, 66, 73, 0.1);
  right: 0;
  width: 14rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-border-default);
  background: var(--clr-bg-primary);
  padding: 0.75rem;
  z-index: var(--z-dropdown);

  &.open {
    display: grid;
    pointer-events: all;
  }

  .menu__item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    overflow: hidden;

    .avatar {
      width: 2.25rem;
      height: 2.25rem;
    }

    .icon-holder {
      display: grid;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.375rem;
      padding: 0.25rem;
      :deep(svg) {
        width: 1rem;
        height: 1rem;
      }
    }

    &__profile {
      color: var(--clr-txt-subtle);
      .icon-holder {
        background-color: var(--clr-bg-tertiary);
      }
    }

    &__logout {
      color: var(--clr-txt-negative);
      .icon-holder {
        background-color: var(--clr-bg-negative);
      }
    }
  }
}
@media screen and (min-width: $mobile) {
  .text__group {
    position: relative;
    border-radius: 0.5rem;
    width: 10rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem;
    padding-right: 0.5rem;

    &:hover {
      background: var(--clr-bg-tertiary);
      cursor: pointer;
    }

    .user__info {
      display: block;
    }

    .user__chevron {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-left: auto;
      transition: all 0.4s ease;
      color: var(--clr-icon-default);

      &.open {
        transform: rotate(-180deg);
      }
    }
  }

  .dropdown__menu {
    top: 3.25rem;
  }
  .menu__item__profile,
  .menu__item__logout {
    &:hover {
      cursor: pointer;

      span {
        text-decoration: underline;
      }
    }
  }
}
</style>
