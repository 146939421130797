<script setup lang="ts">
import { computed } from 'vue'
import { IconBox } from '@keyo/ui'
import type { PropType } from 'vue'
import type { DeviceOrganizationData } from '@/modules/organization/utils'
import { formatDeviceOrganizationAddress } from '@/modules/organization/utils'

const props = defineProps({
  data: {
    type: Object as PropType<DeviceOrganizationData>,
  },
})

const formattedAddress = computed(() =>
  props.data ? formatDeviceOrganizationAddress(props.data.location) : '',
)
</script>

<template>
  <div
    class="device-org"
    :class="{
      skeleton: !data,
    }"
  >
    <template v-if="data">
      <IconBox name="location" />

      <div class="device-org-info">
        <p class="device-org-name text-label">{{ data.organization?.name }}</p>
        <p class="text-body-s">{{ formattedAddress }}</p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.device-org {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem;

  width: 100%;
  min-height: 4rem;
  border-radius: 1rem;
  border: 1px solid var(--color-grey-100);

  &:not(.skeleton) {
    background: white;
  }

  .device-org-info {
    display: grid;
    text-align: left;
  }

  .device-org-name {
    font-weight: 600;
  }
}
</style>
