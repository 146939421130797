import { defineStore } from 'pinia'
import wallet from '../api'
import { useOrganizationsStore } from '@/store'
import { MEMBERSHIP_STATUS } from '@/store/organizations'
import type { Transaction, TransactionResults } from '@/components/Transaction/model'
import { prepareTransaction } from '@/components/Transaction/model'
import type { CardResult, FullCardItem, PartialCardInput } from '@/modules/wallet/model'

import type { AxiosError } from 'axios'
import type { Pagination } from '@/types/pagination'

type CardFetchOptions = Partial<Pagination> & {
  withTransactions: boolean
}

type ErrorDetails = {
  detail?: Array<string>
}

export type AxiosErrorDetails = AxiosError<ErrorDetails>

const convertCardItem = (item: CardResult, orgId: number, memberId: number): FullCardItem => ({
  createdAt: item.creation_date ? new Date(item.creation_date).toLocaleDateString() : undefined,
  // need to store organization and member ids to be able to fetch transactions for this card later on
  orgId,
  memberId,
  ...item,
  id: item.id.toString(),
  colour: item.colour?.value,
})

const sortTransactions = (a: Transaction, b: Transaction) =>
  new Date(b.date_time).getTime() - new Date(a.date_time).getTime()

type StoreState = {
  cardsById: Map<string, FullCardItem>
  transactionsByCardId: Map<string, Transaction[]>
  lastTransactionsByIdResults: TransactionResults
  isCardsFetched: boolean
  userTransactions?: Transaction[]
  lastUserTransactionsResults?: TransactionResults
}

export default defineStore('wallet', {
  state: (): StoreState =>
    ({
      cardsById: new Map(),
      transactionsByCardId: new Map(),
      lastTransactionsByIdResults: null,
      isCardsFetched: false, // flag to check if cards were already fetched to not block render
      userTransactions: undefined, // transactions for the current user
    } as StoreState),
  getters: {
    cards(state): FullCardItem[] {
      return Array.from(state.cardsById.values()).sort(a => (a.is_default ? -1 : 1))
    },
  },
  actions: {
    /**
     * @throws {AxiosErrorDetails}
     */
    async fetchCards(options?: CardFetchOptions) {
      try {
        const organizationsStore = useOrganizationsStore()
        await organizationsStore.getList()

        const orgs = Array.from(organizationsStore.items.values()) as Array<{
          id: number
          member: { id: number; status: { name: string; value: number } }
        }>

        if (!orgs.length) {
          console.error('No organizations found')
          return
        }

        const responses = await Promise.all(
          orgs
            .filter(org => org.member.status.value === MEMBERSHIP_STATUS.ACTIVE)
            .map(org => wallet.listCards({ orgId: org.id, memberId: org.member.id })),
        )

        const cards: Array<FullCardItem> = []
        responses.forEach((resp, i) => {
          cards.push(
            ...resp.data.results.map((itm: CardResult) =>
              convertCardItem(itm, orgs[i].id, orgs[i].member.id),
            ),
          )
        })

        this.$patch(state => {
          cards.forEach(itm => {
            if (!itm.id) return
            state.cardsById.set(itm.id, itm)
          })

          // Reorder cardsById map by is_default
          state.cardsById = new Map(
            Array.from(state.cardsById.values())
              .sort(a => (a.is_default ? -1 : 1))
              .map(itm => [itm.id, itm]),
          )
          state.isCardsFetched = true
        })

        // fetch transactions for the first card
        if (options?.withTransactions) {
          const firstCard = this.cardsById.values().next().value
          if (firstCard && firstCard.id) {
            await this.fetchTransactionsByCard(firstCard.id, {
              limit: options?.limit,
              offset: options?.offset,
            })
          }
        }

        return
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        // ensure the flag is set to true to not block rendering empty cards message
        this.$patch(state => {
          state.isCardsFetched = true
        })
      }
    },

    async fetchTransactionsByCard(cardId: string, { limit, offset }: Pagination = {}) {
      const card = this.cardsById.get(cardId)
      const hasNext = this.lastTransactionsByIdResults
        ? this.lastTransactionsByIdResults.next
        : true

      if (!card || !hasNext) return

      const { data } = await wallet.listTransactionsByCard({
        orgId: card.orgId,
        memberId: card.memberId,
        cardId,
        limit,
        offset,
      })

      const transactions = data?.results
        ? data.results.map(prepareTransaction).sort(sortTransactions)
        : []

      this.$patch(state => {
        this.lastTransactionsByIdResults = data
        const existingTransactions = state.transactionsByCardId.get(cardId) ?? []
        state.transactionsByCardId.set(cardId, [...existingTransactions, ...transactions])
      })

      return
    },

    async fetchUserTransactions({ limit, offset }: Pagination = {}) {
      const hasNext = this.lastUserTransactionsResults
        ? this.lastUserTransactionsResults.next
        : true
      if (hasNext) {
        const { data } = await wallet.listTransactionsUser({
          limit,
          offset,
        })

        this.$patch(state => {
          this.lastUserTransactionsResults = data
          const existingUserTransactions = this.userTransactions ?? []

          const newTransactions = data?.results
            ? data.results.map(prepareTransaction).sort(sortTransactions)
            : []
          state.userTransactions = [...existingUserTransactions, ...newTransactions]
        })
      }
      return { hasNext }
    },

    async update(orgId: number, memberId: number, cardId: string, cardInput: PartialCardInput) {
      try {
        const { data } = await wallet.updateCard({ orgId, memberId, cardId, card: cardInput })
        const cardItem = convertCardItem(data, orgId, memberId)

        // if the current card was set as default, find the prev default card in the organization and unset it
        if (cardInput.is_default) {
          for (const itm of this.cardsById.values()) {
            // make sure it's a different card (using cardId) but in the same org
            if (itm.orgId === orgId && itm.id !== cardId && itm.is_default) {
              this.cardsById.set(itm.id, { ...itm, is_default: false })
              // no need to loop over map anymore
              break
            }
          }
        }

        this.cardsById.set(cardItem.id, cardItem)
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    /**
     * @throws {AxiosErrorDetails}
     */
    async deleteCard(orgId: number, memberId: number, cardId: string) {
      try {
        await wallet.deleteCard({ orgId, memberId, cardId })
        this.cardsById.delete(cardId)
      } catch (e) {
        console.error(e)
        throw e
      }
    },
  },
})
