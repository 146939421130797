import CreateOrganization from './CreateOrganization.vue'
import InviteForm from './InviteForm.vue'
import InviteSent from './InviteSent.vue'
import MembersRemove from './MembersRemove.vue'
import MembersPause from './MembersPause.vue'
import MembersRenew from './MembersRenew.vue'
import ChangeRoleModal from './ChangeRoleModal.vue'
import Gallery from './Gallery.vue'
import LeaveWindow from './LeaveWindow.vue'

import { modals as webhooks } from '@/modules/webhooks'
import { modals as apiCreds } from '@/modules/api-creds'
import { modals as admins } from '@/modules/admins'
import { modals as auth } from '@/modules/auth'
import { modals as account } from '@/modules/account'
import { modals as wallet } from '@/modules/wallet'
import { modals as members } from '@/modules/members'

export default {
  CreateOrganization,
  InviteForm,
  InviteSent,
  ChangeRoleModal,
  MembersRemove,
  MembersPause,
  MembersRenew,
  LeaveWindow,

  Gallery,

  ...webhooks,
  ...apiCreds,
  ...admins,
  ...auth,
  ...account,
  ...wallet,
  ...members,
}
