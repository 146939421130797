export default {
  'Bulk invite': 'Invitación masiva',
  'enterEmail': 'Ingresa el correo electrónico del miembro aquí abajo',
  'selectRole': 'Selecciona el rol del miembro en la organización',
  'Download CSV template': 'Descargar plantilla CSV',
  'fillOutTemplate': 'Descarga y completa esta plantilla para invitar a los miembros.',
  'Download': 'Descargar',
  'Upload CSV file': 'Subir archivo CSV',
  'ensureFilledCorrectly': 'Asegúrate de que el archivo esté bien llenado y sin errores.',
  'acceptsCSVfile': 'Acepta archivo CSV de menos de 5MB',
  'issuesWithContacts':
    'Hemos encontrado contactos en tu archivo { fileName } que no pueden recibir invitaciones. Descarga el archivo para actualizar los contactos con errores, o sigue con las invitaciones solo para los contactos sin errores.',
  'noneContactToInvite':
    'Ninguno de los contactos en tu archivo { fileName } puede recibir la invitación. Sube el archivo de nuevo para actualizar los contactos con errores.',
  'Download error report': 'Descargar informe de errores',
  'ignoreRows': 'Ignorar todas las filas con errores',
  'verifyRecipientsConsent':
    'Verifica que todos los destinatarios hayan dado su consentimiento claro para recibir correos electrónicos de Keyo y asegurar el cumplimiento con las leyes de protección de datos y privacidad.',
  'doYouWantInviteEmail?': '¿Desea invitar a { email } a unirse a su organización?',
  'doYouWantInviteMembers?': '¿Quieres invitar a { count } miembros a unirse a tu organización?',
  'inviteHasSent': 'La invitación ha sido enviada a { email }',
  'invitesAreSent': 'Las invitaciones han sido enviadas a los usuarios.',
  // csv file bulk error report headings
  'members-bulk-invitation-error-report': 'informe-de-errores-de-invitación-masiva-de-miembros',
  'Member email': 'Correo electrónico del miembro',
  'Member role': 'Rol del miembro',
  'Error': 'Error',
  'member': 'miembro | miembros',
  'removeMember?': "¿Remover @:{'member'}?",
  'areYouSureToRemove': '¿Estás seguro de que quieres remover',
  'removingMemberOnlyRevokesAccess':
    'Remover un miembro de la organización solo revoca su acceso a tu organización y no eliminará su cuenta.',
  'membersRemoved': '@.capitalize:member ha sido removido de tu organización.',
}
