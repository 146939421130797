<script lang="ts">
import type { DropdownOption } from '@keyo/ui'
import { Btn, TextField, Dropdown } from '@keyo/ui'
import toast from '@/libs/toast'

import { useCurrentRouteEntities, useModal } from '@/composables'
import { computed, reactive } from 'vue'
import { useUsersStore } from '@/store'
import { mapActions } from 'pinia'
import { required, email } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import { useI18n } from 'vue-i18n'

export default {
  name: 'InviteForm',
  components: { Btn, TextField, Dropdown },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const { handleResponseException } = useFormHelpers()

    const form = reactive({
      email: '',
      role: '',
    })

    const externalResults = reactive({})
    const rules = {
      email: [required(), email()],
      role: [required()],
    }

    const v$ = useVuelidate(rules, form, {
      $externalResults: externalResults,
      $autoDirty: true,
      $rewardEarly: true,
    })

    const modal = useModal()

    const { organization } = useCurrentRouteEntities()

    const rolesOptions = computed(() => {
      const options: DropdownOption[] = []
      organization?.value?.roles.forEach((r: { name: string; id: string }) => {
        if (r.name === 'Owner') return

        options.push({
          id: r.id,
          label: t(`userRoles.${r.name.toLowerCase()}`),
          value: r.id,
        })
      })
      return options
    })

    return {
      form,
      v$,
      externalResults,
      modal,
      rolesOptions,
      toast: toast.show,
      handleResponseException,
    }
  },

  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    ...mapActions(useUsersStore, ['invite']),
    async submitForm() {
      if (this.isSubmitting) return
      this.isSubmitting = true

      await this.v$.$validate()
      if (this.v$.$error) {
        this.isSubmitting = false
        return
      }

      try {
        await this.invite(this.$route.params.id, this.form)
        this.modal.show('invite-sent')
      } catch (e) {
        const { response } = e as AxiosError
        this.handleResponseException(response, this.externalResults)
        this.isSubmitting = false
      }
    },
    cancel() {
      this.modal.hide()
    },
  },
}
</script>

<template>
  <form class="invite__container" @submit.prevent="submitForm">
    <h1 class="invite__title">{{ $t('components.modals.inviteForm.title') }}</h1>
    <div class="invite__form">
      <div class="form__left">
        <p class="invite__text">{{ $t('components.modals.inviteForm.enterUserEmail') }}</p>
        <TextField
          id="email"
          v-model="form.email"
          inputmode="email"
          type="email"
          :state="v$.email.$error ? 'error' : ''"
          :tip="v$.email.$errors?.[0]?.$message"
          :label="$t('common.email')"
        />
      </div>
      <div class="form__right">
        <p class="invite__text">{{ $t('common.selectUserRole') }}</p>
        <!--
          TODO: Update SelectField.vue or use dropdown component from UI package
          in order to make selectedTitle reactive
          (Example: Not updating based on translation locale)
        -->
        <Dropdown
          id="role"
          v-model="form.role"
          :options="rolesOptions"
          :state="v$.role.$error ? 'error' : ''"
          :tip="v$.role.$errors?.[0]?.$message"
          :label="$t('common.organizationRole')"
        />
      </div>
    </div>
    <div class="invite__buttons">
      <Btn type="submit" :is-disabled="v$.$invalid" :is-processing="isSubmitting">
        {{ $t('components.modals.inviteForm.buttons.sendInvite') }}
      </Btn>
      <Btn kind="minimal" @click="cancel">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.invite__container {
  width: 100%;
  max-width: 830px;
}
.invite__title {
  font-weight: bold;
  font-size: 36px;

  margin: 0 0 20px 0;
}
.invite__text {
  font-size: 16px;

  margin: 0 0 18px 0;
}
.invite__form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  min-height: 180px;
  margin: 0 0 30px 0;
}
.form__left {
  padding: 0 14px 0 0;
}
.form__right {
  padding: 0 0 0 14px;
  border-left: 1px solid var(--color-grey-med);
}
.invite__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 11px;
}

:deep(.dropdown) {
  background-color: var(--color-grey-200);

  &:hover {
    border: 1px solid var(--color-grey-400);
  }
}
</style>
