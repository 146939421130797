import { useStorage } from '@vueuse/core'
import { watch } from 'vue'

export const FEATURE_FLAGS = useStorage('FEATURE_FLAGS', {
  ENABLE_BETA_LANGS: import.meta.env.APP_ENABLE_BETA_LANGS === 'true',
})

// Reload the page when the feature flags are updated
watch(FEATURE_FLAGS, () => {
  if (window?.location?.pathname !== '/config') {
    window.location.reload()
  }
})

export const H_CAPTCHA_SITE_KEY = import.meta.env.APP_H_CAPTCHA_PUBLIC_SITE_KEY
