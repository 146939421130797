<script setup lang="ts">
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { Anchor, DialogBtn } from '@keyo/ui'
import { useModal } from '@/composables'
import type { SignInMethod } from '@/api/auth.ts'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  method: SignInMethod
  methodValue: string
  resend: () => void
}>()
const modal = useModal()
const { t } = useI18n()

const i18nMessages = computed(() =>
  props.method === 'phone'
    ? {
        check: t('modules.auth.pages.forgotPasswordInstruction.checkMessages'),
        didNotReceive: t('modules.auth.pages.forgotPasswordInstruction.didNotReceiveMessage'),
        resend: t('modules.auth.pages.forgotPasswordInstruction.resendMessage'),
      }
    : {
        check: t('modules.auth.common.checkEmail'),
        didNotReceive: t('modules.auth.pages.forgotPasswordInstruction.didNotReceiveEmail'),
        resend: t('modules.auth.common.resendEmail'),
      },
)
</script>
<template>
  <ModalCard
    stick-to-bottom
    icon="email-ground"
    icon-color="lavender"
    :heading="i18nMessages.check"
  >
    <template #description>
      <i18n-t keypath="modules.auth.pages.forgotPasswordInstruction.description.checkMethod">
        <template #method>
          <span class="text-label text-bold">{{ methodValue }}</span>
        </template>
      </i18n-t>
    </template>

    <template #buttons>
      <div class="actions">
        <DialogBtn tag="router-link" :to="{ name: 'login' }" @click="modal.hide()">
          {{ $t('buttons.gotIt') }}
        </DialogBtn>

        <p>
          {{ i18nMessages.didNotReceive }}
          <Anchor tag="button" @click="resend?.()">
            {{ i18nMessages.resend }}
          </Anchor>
        </p>
      </div>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
.actions {
  display: grid;
  gap: 1rem;
  text-align: center;
  flex: 1;
  p {
    padding-bottom: 0.25rem;
  }

  p,
  a {
    font: var(--text-link);
  }
}
</style>
