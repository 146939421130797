<script>
import toast from '@/libs/toast'

import TextField from '@/components/inputs/TextField'
import { Btn } from '@keyo/ui'
import IconBox from '@/components/IconBox'

import { mapActions } from 'pinia'
import { useWebhooksStore } from '../pinia'
import { useModal, useFormValidation } from '@/composables'
import useFormHelpers from '@/composables/useFormHelpers'

export default {
  components: { Btn, TextField, IconBox },
  setup() {
    const { form, errors, isFormValid, validateForm } = useFormValidation({
      url: ['required'],
    })
    const modal = useModal()

    const { handleResponseException } = useFormHelpers()

    return {
      form,
      errors,
      isFormValid,
      validateForm,
      handleResponseException,
      modal,
      toast: toast.show,
    }
  },

  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    ...mapActions(useWebhooksStore, ['create']),
    async submitForm() {
      if (this.isSubmitting) return
      this.isSubmitting = true
      if (!this.validateForm()) {
        this.isSubmitting = false
        return
      }
      try {
        await this.create(this.$route.params.id, this.form)
        this.modal.hide()
      } catch (e) {
        this.handleResponseException(e.response, this.errors)
        this.isSubmitting = false
      }
    },
  },
}
</script>

<template>
  <form class="webhook-create__container" @submit.prevent="submitForm">
    <IconBox>
      <img src="../assets/icons/webhook-add.svg" :alt="$t('modules.webhooks.plusIcon')" />
    </IconBox>
    <h1 class="webhook-create__title">{{ $t('modules.webhooks.webhookCreation') }}</h1>
    <p class="webhook-create__text">
      {{ $t('modules.webhooks.setupInfo') }}
    </p>
    <TextField
      id="url"
      v-model="form.url"
      :state="errors.url ? 'error' : ''"
      :tip="errors.url"
      :label="$t('modules.webhooks.endpointURL')"
    />
    <TextField
      id="secret"
      v-model="form.secret_token"
      type="password"
      :state="errors.secret_token ? 'error' : ''"
      :tip="errors.secret_token"
      :label="$t('modules.webhooks.secret')"
    />
    <div class="webhook-create__buttons">
      <Btn type="submit" :is-disabled="!isFormValid" :is-processing="isSubmitting">
        {{ $t('modules.webhooks.createEndpoint') }}
      </Btn>
      <Btn kind="minimal" @click="modal.hide">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.webhook-create__container {
  width: 100%;
  max-width: 620px;
}
.webhook-create__title {
  font-weight: 700;
  font-size: 36px;

  margin: 0 0 20px 0;
}
.webhook-create__text {
  font-size: 16px;

  margin: 0 0 18px 0;
}
.webhook-create__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 11px;
}
</style>
