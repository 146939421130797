<script lang="ts" setup>
import { DialogBtn } from '@keyo/ui'
import { useModal } from '@/composables'
import { ref } from 'vue'
import { useWalletStore } from '@/modules/wallet/pinia'
import ModalCardSuccess from '@/components/modals/components/ModalCardSuccess.vue'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import type { AxiosError } from 'axios'

const props = defineProps({
  cardId: {
    type: String,
    required: true,
  },
  orgId: {
    type: Number,
    required: true,
  },
  memberId: {
    type: Number,
    required: true,
  },
})

const modal = useModal()
const wallet = useWalletStore()
const { handleResponseException } = useFormHelpers()

const isSubmitting = ref(false)

async function performDelete() {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    await wallet.deleteCard(props.orgId, props.memberId, props.cardId)
    modal.show('card-delete-success', { isCustomStyle: true })
  } catch (e) {
    const { response } = e as AxiosError
    handleResponseException(response, {}, { useToast: true })
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <ModalCardSuccess
    icon="delete"
    icon-color="red"
    :heading="$t('modules.wallet.modals.cardDeleteConfirm.heading')"
  >
    <p class="text-body-m">
      {{ $t('modules.wallet.modals.cardDeleteConfirm.message.part1') }}
      <strong>{{ $t('modules.wallet.deleteCard') }}</strong
      >{{ $t('modules.wallet.modals.cardDeleteConfirm.message.part2') }}
    </p>

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide()">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" danger @click="performDelete">
        {{ $t('modules.wallet.deleteCard') }}
      </DialogBtn>
    </template>
  </ModalCardSuccess>
</template>
