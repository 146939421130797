import { type UserRole } from '@/types/user.ts'
import { i18n } from '@keyo/core/i18n'

export type DeviceOrganizationDataLocation = {
  country: {
    code: string
    name: string
  }
  city: string
  street: string
  street_address_line: string
  zip_code: number
  id: number
  state: string
}

export type DeviceOrganizationData = {
  id: number
  location: DeviceOrganizationDataLocation
  organization: {
    id: number
    name: string
    business_name: string
    logo: string
  }
}

// Currently it is specific to US but can be extended in the future
export const formatDeviceOrganizationAddress = (
  location: DeviceOrganizationDataLocation,
): string => {
  if (!location) return ''
  const { street_address_line, street, city, state, zip_code } = location
  return `${street_address_line ?? ''} ${street ?? ''} ${city ?? ''}, ${state ?? ''} ${
    zip_code ?? ''
  }`
}
