import FileSaver from 'file-saver'
import { isNumber } from 'lodash'
import { apiV3 } from '@/api'

type ID = string | number

type InviteBody = {
  email: string
  role: string
}

export const invite = (orgId: ID, body: InviteBody) => {
  const url = `/organizations/${orgId}/invite-user/`
  return apiV3.post(url, body)
}

export const inviteValidate = (orgId: ID, body: InviteBody) => {
  const url = `/organizations/${orgId}/validate-invite-user/`
  return apiV3.post(url, body)
}

export async function downloadCsvTemplate(orgId: ID) {
  const resp = await apiV3.get(`/organizations/${orgId}/invitation-standard-csv/`, {
    responseType: 'blob',
  })
  FileSaver.saveAs(resp.data, 'bulk-invite-template.csv')
}

export async function inviteCsv(orgId: ID, file: File) {
  const formData = new FormData()
  formData.append('file', file)
  return apiV3.post(`/organizations/${orgId}/bulk-invitation-csv/`, formData)
}

export async function inviteCsvCheck(
  orgId: ID,
  file: File,
  onProgress?: (progress: number) => void,
) {
  onProgress?.(0)

  const formData = new FormData()
  formData.append('file', file)

  return apiV3.post(`/organizations/${orgId}/bulk-validate-csv/`, formData, {
    onUploadProgress(e) {
      if (!onProgress || !isNumber(e.total)) return
      onProgress(e.loaded / e.total)
    },
  })
}
