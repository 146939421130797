export default {
  common: {
    createNewOrganization: 'Créer une nouvelle organisation',
    helpUsEnhance: 'Aidez-nous à améliorer nos services !',
    suggestionsAreValuable: 'Vos suggestions nous sont précieuses !',
  },
  components: {
    actionsDropdown: {
      hideMenu: 'cacher le menu',
      showMenu: 'Afficher le menu',
    },
    btnResend: {
      resendSecurityCode: 'Renvoyer le code de sécurité',
      resendSecurityCodeIn: 'Renvoyer le code de sécurité à {remaining}s',
    },
    modals: {
      'Leave this window?': 'Quitter cette fenêtre ?',
      'changeRole': {
        roleChanged: 'Le rôle a été modifié avec succès',
      },
      'createOrganization': {
        created: "L'organisation a été créée",
        failedCreated: "Échec de la création d'une organisation",
        subtitle: 'Veuillez remplir le formulaire ci-dessous.',
        title: "Création d'une organisation",
      },
      'inviteForm': {
        buttons: {
          sendInvite: 'Envoyer une invitation',
        },
        enterUserEmail: "Saisissez l'adresse électronique de l'utilisateur ci-dessous",
        selectUserRole: "Sélectionner le rôle de l'utilisateur",
        title: "Invitation de l'utilisateur",
      },
      'inviteSent': {
        buttons: {
          done: "J'ai fini.",
          inviteAnotherUser: 'Inviter un autre utilisateur',
        },
        description:
          "L'utilisateur a reçu un lien pour créer un profil. Lorsqu'il aura terminé, son nom apparaîtra dans votre liste d'utilisateurs.",
        title: 'Invitation envoyée !',
      },
      'leaveWithoutSaving?':
        'Êtes-vous sûr de vouloir quitter cette fenêtre ? Les modifications que vous apportez ne peuvent pas être sauvegardées.',
      'membersPause': {
        areYouSure: 'Êtes-vous sûr de vouloir faire une pause ?',
        confirmText:
          'Cliquez sur "confirmer" pour continuer. Les utilisateurs recevront un courriel les informant de la mise en pause de leur compte.',
        pauseError: 'Erreur inattendue lors de la mise en pause de {name}. Réessayez plus tard',
        pauseSuccess: 'Le site {name} a été mis en pause.',
        title: 'Pause utilisateur',
      },
      'membersRenew': {
        areYouSure: 'Êtes-vous sûr de vouloir renouveler',
        confirmText:
          'Cliquez sur "confirmer" pour continuer. Les utilisateurs recevront un courriel les informant du renouvellement de leur adhésion.',
        renewError:
          'Erreur inattendue lors de la tentative de renouvellement de {name}. Veuillez réessayer plus tard',
        renewSuccess: 'Le membre {name} a été renouvelé.',
        title: "Renouveler l'utilisateur",
      },
      'videoPlayer': {
        notSupported: 'Votre navigateur ne supporte pas la vidéo',
      },
    },
    pagination: {
      ofTotalPages: 'de {totalPages}',
    },
  },
  layouts: {
    administrator: 'Administrateur',
    apiReference: 'Référence API',
    biometrics: 'Biométrie',
    dashboard: 'Tableau de bord',
    developers: 'Développeurs',
    developersHub: 'Carrefour des développeurs',
    deviceManagement: 'Gestion des appareils',
    helpCenter: "Centre d'aide",
    invitations: 'Invitations',
    keyoLogoSquare: 'Logo carré Keyo',
    keyoLogoWide: 'Logo large Keyo',
    knowledgeHub: 'Centre de connaissances',
    logOut: 'Se déconnecter',
    metrics: 'Métriques',
    personal: 'Personnel',
    personalAccount: 'compte personnel',
    postmanCollection: 'Collection du facteur',
    preferences: 'Préférences',
    profileSettings: 'Paramètres du profil',
    settings: 'Paramètres',
    systemActivityEmulator: "Simulateur d'activité",
    toggleSidebar: 'Barre latérale',
    webhookManagement: 'Gestion des webhooks',
  },
  modules: {
    account: {
      common: {
        changeEmail: "Modifier l'adresse électronique",
        changePhoneNumber: 'Changement de numéro de téléphone',
        securityCodeSentToMethod:
          'Un code de sécurité sera envoyé à {method}. Veuillez saisir le code à 6 chiffres ci-dessous.',
        securityCodeSentToPhone:
          'Un code de sécurité sera envoyé à {phone}. Veuillez saisir le code à 6 chiffres ci-dessous.',
      },
      components: {
        cardAccountDelete: {
          deleteAccountConfirmation: 'Êtes-vous sûr de vouloir supprimer votre compte ?',
          deletionNote: {
            part1:
              "Veuillez noter que la suppression de votre compte, telle qu'elle est prévue dans le",
            part2:
              "entraînera également l'effacement de vos données biométriques conformément à la",
            part3:
              "Cette action désactivera les fonctions d'authentification biométrique et de paiement, ce qui signifie que vous ne pourrez plus utiliser vos informations biométriques pour accéder à ce système ou y effectuer des paiements.",
          },
          generalWarning:
            "Si vous avez rencontré un problème avec votre compte et que vous avez besoin d'aide, veuillez nous contacter afin que nous puissions vous aider.",
          ownerWarning:
            "En tant que propriétaire de l'organisation, vous avez la possibilité de supprimer votre compte. Toutefois, veuillez noter que si vous choisissez de supprimer votre compte, vous devrez désigner quelqu'un d'autre comme nouveau propriétaire de l'organisation. Une fois votre compte supprimé, vous n'aurez plus accès aux fonctions de gestion et d'administration de l'organisation.",
        },
        phoneAdd: {
          toAddEnterPhoneNumber:
            'Pour ajouter votre numéro de téléphone, veuillez le saisir dans le champ ci-dessous.',
        },
      },
      modals: {
        accountBiometricAlreadyExists: {
          description: 'Désolé, vous avez déjà ajouté vos données biométriques à votre compte.',
        },
        accountBiometricConfirmEnroll: {
          almostDone: 'Vous avez presque terminé !',
          deviceMissingError: 'Le dispositif est manquant',
          followInstructions: 'Suivez les instructions sur le Keyo Wave+',
          orgLegalMessage:
            'En adhérant à cette organisation, vous acceptez les conditions de ce partenaire {legal}.',
          organizationMissingError: "L'organisation est absente",
          startEnrollmentQuestion: 'Souhaitez-vous commencer à vous inscrire à :',
        },
        accountBiometricDelete: {
          deleteBiometric: 'Supprimer la biométrie',
          description: {
            part1:
              'Veuillez saisir ci-dessous le code de sécurité que nous avons envoyé à votre {method}. Une fois que vous aurez tapé',
            part3: 'vos données biométriques seront définitivement supprimées de Keyo.',
          },
          heading: 'Supprimez vos données biométriques',
        },
        accountBiometricDeleteSuccess: {
          description: 'Vos données biométriques ont été supprimées sur la base de notre',
          heading: 'Biométrie supprimée',
        },
        accountDelete: {
          description: {
            part1:
              'Veuillez saisir le code de sécurité que nous avons envoyé à votre {method}. Une fois que vous aurez tapé',
            part2: 'votre compte sera supprimé comme indiqué dans notre',
          },
          termsOfUse: "Conditions d'utilisation.",
        },
        accountDeleteSuccess: {
          description: 'Votre compte a été supprimé sur la base de notre',
          heading: 'Compte supprimé',
        },
        accountEmailChangeStep1: {
          description:
            'Un code de sécurité sera envoyé à {email}. Veuillez saisir le code à 6 chiffres ci-dessous.',
        },
        accountEmailChangeStep2: {
          description:
            'Pour mettre à jour votre adresse électronique, veuillez saisir votre nouvelle adresse électronique dans le champ ci-dessous.',
          labelNewEmail: 'Saisir un nouvel e-mail',
        },
        accountEmailChangeStep3: {
          description:
            'Un code de sécurité sera envoyé à {newEmail}. Veuillez saisir le code à 6 chiffres ci-dessous.',
          heading: 'Confirmer le nouvel e-mail',
        },
        accountEmailChangeSuccess: {
          description:
            'Vous pouvez désormais utiliser un nouvel e-mail pour vous connecter à votre compte.',
        },
        accountPasswordChange: {
          confirmNewPassword: 'Confirmer le nouveau mot de passe',
          description:
            'Pour mettre à jour votre mot de passe, veuillez saisir votre mot de passe actuel dans le champ ci-dessous.',
          currentPassword: 'Mot de passe actuel',
          heading: 'modifier le mot de passe',
          newPassword: 'Nouveau mot de passe',
          step1: 'Étape 1',
          step2: 'Étape 2',
        },
        accountPasswordChangeSuccess: {
          description:
            'Vous pouvez maintenant utiliser votre nouveau mot de passe pour vous connecter à votre compte.',
        },
        accountPhoneAddSuccess: {
          description:
            'Vous pouvez désormais utiliser votre numéro de téléphone pour vous connecter à votre compte.',
        },
        accountPhoneChangeStep2: {
          description:
            'Pour mettre à jour votre numéro de téléphone, veuillez saisir votre nouveau numéro de téléphone dans le champ ci-dessous.',
          labels: {
            enterNewPhone: 'Saisir le nouveau numéro de téléphone',
          },
        },
        accountPhoneChangeStep3: {
          description:
            'Un code de sécurité sera envoyé à {newPhone}. Veuillez saisir le code à 6 chiffres ci-dessous.',
          heading: 'Confirmer le nouveau numéro de téléphone',
        },
        accountPhoneChangeSuccess: {
          description:
            'Vous pouvez désormais utiliser votre nouveau numéro de téléphone pour vous connecter à votre compte.',
        },
        accountSecurityMfaConfirm: {
          areYouSureYouWantToChange2fa:
            "Êtes-vous sûr de vouloir changer la méthode d'authentification à deux facteurs par défaut en {method}?",
          change2faAuthentication: "Modifier l'authentification à deux facteurs",
          changeTo: 'Passer à {method}',
        },
        accountSecurityMfaSuccess: {
          description:
            "Vous avez changé votre méthode d'authentification à deux facteurs par défaut en {method}.",
        },
        accountVerifyMethodSuccess: {
          methodHasBeenVerified: 'Votre {method} a été vérifié avec succès.',
        },
      },
      pages: {
        profileEdit: {
          saveChanges: 'Enregistrer les modifications',
          uploadInfo:
            "Vous pouvez télécharger un fichier .jpg ou .png de l'utilisateur jusqu'à 2MB.",
        },
        security: {
          addButton: 'Ajouter',
          changeButton: 'Changer',
          passwordPlaceholder: '*************',
          passwordTitle: 'mot de passe',
        },
        securityMfa: {
          receiveCodeMethod: 'Recevez le code de vérification via {method}.',
          updateYourMfa:
            "Vous pouvez mettre à jour votre méthode par défaut pour l'authentification à deux facteurs ici.",
          verifyMethod:
            'Pour recevoir le code de vérification via {method}, vous devez terminer la vérification.',
        },
      },
    },
    activityEmulator: {
      action: 'Action',
      emulateFailedPalmIdentification: "Emulation de l'échec de l'identification des palmiers",
      emulationError: "Erreur d'émulation inattendue.",
      emulationSuccess: 'Emulation réussie de "{action}" pour {user}.',
      enroll: "S'inscrire",
      failScanEmulationSuccess: "Emulation réussie de l'erreur d'identification.",
      identificationFailed: "Échec de l'identification",
      identify: 'Identifier',
      perform: 'Exécuter',
      systemActivityEmulator: "Émulateur d'activité du système",
    },
    admin: {
      common: {
        featureRequest:
          "Il manque une fonctionnalité ? Demandez à l'ajouter à notre feuille de route",
      },
      confirmOwnershipTransfer: 'Veuillez confirmer le transfert de propriété',
      confirmOwnershipTransferDesc:
        'En saisissant le code de sécurité et en appuyant sur Confirmer, la propriété de votre organisation sera transférée à {nextOwner}.',
      dashboardDescription:
        'Bienvenue sur le tableau de bord de votre organisation ! De là, vous pouvez accéder aux outils de développement, acheter des appareils, inviter des administrateurs, des développeurs et des membres, et bien plus encore !',
      deviceInfo: {
        saveSuccess: "Sauvegarde des informations sur l'appareil",
        unexpectedError: "Erreur inattendue lors de la mise à jour du profil de l'appareil",
      },
      deviceLocationGallery: {
        addMorePhotos: 'Ajouter des photos',
        showGallery: 'Afficher la galerie',
      },
      enterEmail: "Saisir l'adresse électronique",
      enterSecurityCode:
        'Veuillez saisir le code à 6 chiffres envoyé à {email} pour confirmer le transfert de propriété.',
      feedback: "Vous avez des commentaires ?\nNous serions ravis d'en savoir plus !",
      invitationSent: "L'invitation a été envoyée à {email}",
      invite: 'Invitation',
      inviteMemberDescription: 'Vous pouvez le faire avec le tableau de bord !',
      inviteMemberTitle: 'Vous souhaitez inviter un nouveau membre ?',
      inviteMembersToYourOrganization: 'Inviter des membres à rejoindre votre organisation',
      inviteUsers: {
        message: 'Invitez vos premiers utilisateurs !',
      },
      learnMoreAboutKeyo: "En savoir plus sur le tableau de bord d'administration de Keyo",
      letsBuild: "Construisons quelque chose d'extraordinaire !",
      membersInYourOrganization: 'les membres de votre organisation',
      noAdmins: "Pas d'administrateurs",
      noDevices: {
        addDevices: 'Ajouter des dispositifs',
        displaySoon: 'Ils seront bientôt affichés ici',
        workingOnIt: "L'équipe de Keyo y travaille !",
      },
      organizationDashboard: '{name} Tableau de bord',
      organizationOwner: "Propriétaire de l'organisation",
      ownerDescription: 'Cela signifie que seule cette personne dispose de tous les droits.',
      resendSecurityCode: 'Renvoyer le code de sécurité',
      scanningOnWave:
        "Scanner de la paume de la main sur un dispositif d'identification biométrique Keyo Wave+.",
      securityCode: 'code de sécurité',
      selectAdmin: 'Veuillez sélectionner un administrateur pour le transfert de propriété.',
      setOrInviteAdmin:
        'Vous devez désigner un administrateur parmi les utilisateurs existants ou inviter un administrateur.',
      transferOwnership: 'Transfert de propriété',
      userCheckMarkIcon: 'Utilisateur dont la case est cochée',
      usersListSearch: {
        pauseMembers: 'Membres en pause',
        removeMembers: 'Supprimer des membres',
        renewMembers: 'Renouveler les membres',
      },
      you: 'Vous',
    },
    apiCreds: {
      createSecretKey: 'Créer une clé secrète',
      credentialsName: "Nom des informations d'identification",
      keyDescription:
        "La clé vous permettra d'authentifier les demandes d'API.\nConservez toujours votre clé secrète dans un endroit sûr.",
      modals: {
        secretCreated: {
          description:
            "Veillez à l'enregistrer, car vous ne pourrez plus y accéder. Si vous perdez cette clé secrète, vous devrez en générer une nouvelle.",
        },
        secretRotateConfirm: {
          description: 'Êtes-vous sûr de vouloir faire pivoter la clé secrète ?',
          title: 'Rotation de la clé secrète ?',
        },
      },
      noApiCredentials: "Pas d'identifiants API",
      rotate: 'Rotation',
      secretKey: 'Clé secrète',
      toGetStarted: 'Pour commencer, cliquez sur "Créer une clé secrète" pour en créer une.',
    },
    auth: {
      common: {
        activationAccountExpiredLink:
          "Le lien d'activation de votre compte n'est plus valide. Veuillez indiquer l'adresse électronique associée à votre compte Keyo pour demander un nouveau lien d'activation.",
        activationLinkExpired: "Le lien d'activation a expiré",
        changeVerificationMethod: 'Modifier la méthode de vérification',
        checkEmail: 'Vérifiez votre courrier électronique',
        completeFormToCreateAccount:
          'Veuillez remplir le formulaire ci-dessous pour créer votre compte.',
        didNotGetCode: "Vous n'avez pas reçu de code de sécurité ?",
        emailOrPhoneNumberExample: "Exemple de nom{'@'}example.com ou +1123456789.",
        expiredLink:
          'Le lien est expiré ou invalide. Veuillez vérifier votre courrier électronique pour obtenir des instructions supplémentaires ou en demander un nouveau.',
        forgotPassword: 'Mot de passe oublié ?',

        pleaseAddMethod: 'Veuillez ajouter votre {method}',
        pleaseVerifyMethod:
          'Pour protéger votre identité, veuillez vérifier votre adresse {method}.',
        resendEmail: 'Renvoyer le courriel',
        unexpectedLoginSessionError: 'Erreur de session de connexion inattendue.',
        verifyMethod: 'Vérifier {method}',
      },
      components: {
        policyInput: {
          readAndAccept: "J'ai lu et j'accepte",
        },
      },
      modals: {
        activationLinkSuccess: {
          description:
            'Veuillez activer votre compte en utilisant le message que nous avons envoyé à votre {method}.',
          heading: 'Vérifiez votre {method}',
          methods: {
            email: 'Courriel',
            phone: 'Téléphone',
          },
        },
      },
      pages: {
        activationEmail: {
          cannotResendEmail: "Nous ne pouvons pas renvoyer l'e-mail. Veuillez réessayer plus tard.",
          checkEmail:
            "Vérifiez votre courrier électronique et cliquez sur le lien d'activation pour continuer.",
          confirmationEmailResent: 'Un courriel de confirmation vous a été envoyé !',
          emailSentTo: 'Nous avons envoyé un courriel de confirmation à',
        },
        activationPassword: {
          description: 'Veuillez compléter le formulaire ci-dessous pour activer votre compte.',
          heading: 'Créez votre mot de passe',
          readAndAccept: "J'ai lu et j'accepte",
          readOur: 'Lire notre',
        },
        activationPolicies: {
          description: "Veuillez prendre le temps de lire et d'accepter nos politiques.",
          heading:
            "Accepter les conditions d'utilisation et la politique de confidentialité de Keyo",
        },
        activationSecureAccount: {
          description:
            'Veuillez créer un mot de passe pour vous connecter et assurer la sécurité de votre compte.',
          heading: 'Sécurisez votre compte',
        },
        activationVerifyInstruction: {
          ifYouHaveNotReceivedCode:
            "Si vous n'avez pas reçu le code, veuillez mettre à jour votre {method} ou choisir une autre méthode de vérification.",
          pleaseSelectPreferredMethodForVerifying:
            'Veuillez sélectionner la méthode que vous préférez pour vérifier votre compte. En utilisant la méthode choisie, vous pourrez vous connecter à votre compte.',
          verifyItIsYou: "Vérifier qu'il s'agit bien de vous",
        },
        activationVerifySet: {
          description:
            'Un code de sécurité sera envoyé à {recipient}.\n Veuillez saisir le code à 6 chiffres ci-dessous.',
          verifyEmail: "Vérifier l'adresse électronique",
          verifyPhoneNumber: 'Vérifier le numéro de téléphone',
        },
        createAccount: {
          hint: 'En poursuivant, vous acceptez les sites {terms} et {privacy} de Keyo.',
        },
        displayName: {
          description: 'Nous utiliserons ce nom lorsque nous vous accueillerons',
        },
        editMethod: {
          descriptionEmail: 'Veuillez mettre à jour votre adresse électronique ci-dessous.',
          descriptionPhone: 'Veuillez mettre à jour votre numéro de téléphone ci-dessous.',
          headingEmail: "Modifier l'e-mail",
          headingPhone: 'Modifier le numéro de téléphone',
        },
        forgotPasswordExpired: {
          description:
            'Le lien de réinitialisation du mot de passe fourni a expiré. Vous pouvez en demander un autre ci-dessous.',
          heading: 'Lien expiré',
          tryAgain: 'Réessayer',
        },
        forgotPasswordInstruction: {
          checkMessages: 'Vérifiez vos messages',
          description: {
            checkMethod:
              'Un lien de réinitialisation du mot de passe a été envoyé à {method}. Ouvrez le lien pour continuer.',
            enterEmail:
              'Pour réinitialiser votre mot de passe, veuillez saisir votre adresse électronique ou votre numéro de téléphone ci-dessous.',
          },
          didNotReceiveEmail: "Vous n'avez pas reçu de courriel ?",
          didNotReceiveMessage: "Vous n'avez pas reçu de message ?",
          heading: 'Mot de passe oublié',
          resendMessage: 'Renvoyer le message',
        },
        forgotPasswordSet: {
          createNewPassword: 'Créer un nouveau mot de passe',
          enhanceAccountSecurity: 'Renforcez la sécurité de votre compte avec un mot de passe fort',
          youCanNowUseYourNewPassword:
            'Vous pouvez maintenant utiliser votre nouveau mot de passe pour vous connecter à votre compte.',
        },
        fullName: {
          enterDescription:
            "Veuillez compléter le formulaire ci-dessous pour terminer l'activation de votre compte.",
          enterHeading: 'Saisissez votre nom complet',
          reviewDescription:
            'Veuillez vous assurer que les informations ci-dessous sont correctes.',
          reviewHeading: 'Révisez votre nom complet',
        },
        kioskActivationResend: {
          resendActivationLink: "Renvoyer le lien d'activation",
        },
        kioskActivationResendSuccess: {
          description: "Un nouveau lien d'invitation a été envoyé à ",
        },
        login: {
          loginWithApple: 'Se connecter avec Apple',
          loginWithEmail: "Se connecter avec l'email",
          loginWithGoogle: 'Se connecter avec Google',
        },
        loginWithEmail: {
          description:
            'Bienvenue ! Veuillez saisir votre adresse électronique ou votre numéro de téléphone, ainsi que votre mot de passe.',
        },
        register: {
          description: 'Commencez par créer votre compte.',
          signUpWithApple: "S'inscrire auprès d'Apple",
          signUpWithEmail: "S'inscrire avec l'email",
          signUpWithGoogle: "S'inscrire avec Google",
        },
        registerWithEmail: {
          expiredLinkTitle: "Le lien d'activation a expiré",
        },
        secureCode: {
          securityCodeHasBeenSent:
            'Un code de sécurité sera envoyé à {recipient}.\\A Veuillez saisir le code à 6 chiffres ci-dessous',
          verifyIdentity: 'Vérifier votre identité',
        },
        signIn: {
          description: 'Veuillez remplir le formulaire ci-dessous pour vous connecter.',
          descriptionInactive:
            'Veuillez saisir votre adresse électronique ou votre numéro de téléphone.',
          wrongCredentials: {
            contactSupport: 'contacter le support.',
            text: "Veuillez vérifier votre adresse électronique ou votre numéro de téléphone et votre mot de passe, en vous assurant qu'ils correspondent aux informations d'identification utilisées lors de l'inscription. Si le problème persiste, veuillez",
          },
        },
        signup: {
          description:
            'Pour activer votre Keyo Dashboard, veuillez compléter le formulaire ci-dessous.',
          readAndAccept: "J'ai lu et j'accepte",
        },
      },
    },
    devtoolsOverview: {
      apiInteraction:
        "Apprendre à interagir avec les données de l'organisation et de l'utilisateur via l'API Keyo",
      feedback: "Vous avez des commentaires ? Nous serions ravis d'en savoir plus !",
      integrationGuide:
        "En savoir plus sur l'intégration des applications client et serveur Keyo dans votre application",
      pageDesc:
        "Bienvenue à la présentation de vos outils de développement ! Vous trouverez ci-dessous des liens rapides vers diverses ressources qui vous aideront à démarrer. Vous avez des commentaires ou des demandes de fonctionnalités ? N'hésitez pas à nous les soumettre !",
      playVideo: 'Jouer la vidéo',
      videoDesc:
        'Regardez cette vidéo rapide pour en savoir plus sur le tableau de bord du développeur.',
    },
    personal: {
      components: {
        activateAccountWidget: {
          description:
            'Veuillez activer votre compte en utilisant le message que nous vous avons envoyé à votre adresse électronique ou à votre numéro de téléphone.',
          done: 'Terminé',
        },
        cardChangeMfaMethod: {
          ifHaveNotReceivedCode:
            "Si vous n'avez pas reçu le code, veuillez choisir une autre méthode de vérification.",
        },
        onBoardingProgress: {
          title: 'Onboarding - {percentage}% terminé',
        },
        policiesWidget: {
          description:
            'Vous trouverez ici toutes les polices acceptées et signées. Il vous suffit de cliquer sur la politique que vous souhaitez consulter.',
          heading: 'Votre consentement est très important pour nous',
        },
        policyCard: {
          acceptanceDate: "Date d'acceptation",
        },
        qrScanCard: {
          enrollStep1: "Rendez-vous sur un appareil Keyo Wave+ pour l'inscription.",
          enrollStep2: {
            part2: '"Inscrivez votre paume',
            part3: 'dans le menu.',
          },
          enrollStep3: {
            part2: '"Scanner le code QR"',
            part3: 'et utilisez votre appareil mobile.',
          },
          enrollTitle: "L'inscription de votre palmier est facile !",
          label: 'Inscription',
          unlockSubtitle:
            'Pour enregistrer votre palmier, rendez-vous sur l\'un de nos appareils Wave+ et appuyez sur "Scanner le code QR".',
          unlockTitle: "Déverrouillez le monde d'une simple vague !",
        },
      },
      modals: {
        noAccessToCamera: {
          description: {
            part1:
              "Pour continuer, vous devez autoriser l'accès à l'appareil photo dans vos paramètres. Nous avons préparé un guide pour vous",
            part2: 'ici.',
          },
          heading: "Veuillez autoriser l'accès à votre appareil photo",
        },
        qrScanner: {
          invalidQrMessage:
            "Le code QR que vous avez scanné n'est pas valide pour l'inscription. Veuillez réessayer plus tard.",
        },
      },
      pages: {
        editProfile: 'Modifier le profil',
        home: {
          educationWidget: {
            description: "En savoir plus sur l'adhésion.",
            title: 'Pourquoi vous aimerez être membre de Keyo',
          },
          greeting: 'Bonjour, {name}!',
          keyoLogo: 'Logo Keyo',
          networkWidget: {
            label: 'Votre réseau',
          },
          privacyDesignWidget: {
            title: 'Comment Keyo conçoit le respect de la vie privée',
          },
          setupWidget: {
            title: "Consultez votre profil et choisissez un nom d'affichage",
          },
          transactionsWidget: {
            label: 'Transactions',
          },
          welcomeDescription: {
            keyoDashboard: 'Tableau de bord Keyo',
            part1: 'Bienvenue à votre',
            part2:
              'Vous pouvez y gérer votre compte, vos données biométriques et vos méthodes de paiement.',
          },
        },
        invitations: {
          decline: 'Déclin',
          declined: 'Refusé',
          description: 'Voici vos dernières invitations.',
          hasInvited: 'vous invite à rejoindre',
          join: 'Rejoindre',
          joined: 'Rejoint',
        },
      },
      store: {
        deletePhotoError: "Erreur lors de la suppression d'une photo",
        uploadPhotoError: 'Erreur lors du téléchargement de la photo',
        uploadPhotoSuccess: 'La photo a été mise à jour',
      },
    },
    wallet: {
      components: {
        paymentCardList: {
          default: 'Défaut',
          expirationDate: "Date d'expiration",
        },
      },
      defaultCard: 'Carte par défaut',
      deleteCard: 'Supprimer la carte',
      modals: {
        cardDeleteConfirm: {
          heading: 'Supprimer votre carte ?',
          message: {
            part1: 'Une fois que vous avez tapé sur',
            part2: 'votre carte sera définitivement supprimée de votre compte.',
          },
        },
        cardDeleteSuccess: {
          description: 'Votre carte a été supprimée avec succès.',
        },
      },
      noCards: {
        description: "Vous n'avez pas de cartes dans votre portefeuille",
        title: 'Pas de carte ajoutée',
      },
      noTransactions: {
        description: "Vous n'avez pas encore de transactions.",
        title: 'Aucune transaction',
      },
      pages: {
        cardName: 'Nom de la carte',
        chooseAccent: "Choisir l'accent",
        editCard: 'Editer la carte',
        selectedCard: 'Votre carte sélectionnée',
        transactionHistory: 'Historique des transactions',
      },
      setAsDefault: {
        description: 'Cette carte sera utilisée par défaut pour vos paiements.',
        title: 'Définir par défaut',
      },
      transactions: {
        failed: 'Échec',
        refunded: 'Remboursé',
      },
    },
    webhooks: {
      active: 'Actif',
      createEndpoint: 'Créer un point de terminaison',
      created: 'Créée',
      delete: 'Supprimer',
      deleteInfo:
        "La suppression de ce webhook signifie qu'aucun message webhook ne sera envoyé lorsque cet événement se produit au sein de votre organisation.",
      deleteWebhook: 'Supprimer le webhook ?',
      description:
        "Les webhooks sont un moyen pour un système de notifier un autre système, complètement séparé, lorsque certains événements se produisent. Plus précisément, un webhook est un point de terminaison API que vous créez sur votre serveur, avec un code pour traiter les données d'événement provenant du backend de Keyo.",
      disable: 'Désactiver',
      disableIcon: 'Webhook avec icône de désactivation',
      disableWebhook: 'Désactiver le webhook ?',
      disableWebhookInfo:
        "En désactivant temporairement ce point de terminaison du webhook, il cessera de recevoir des notifications d'événements.",
      disabled: 'Handicapés',
      enable: 'Activer',
      enableIcon: "Webhook avec icône d'activation",
      enableWebhook: 'Activer le webhook ?',
      enableWebhookInfo:
        'En activant ce point de terminaison webhook, il commencera à recevoir des notifications sur les événements.',
      endpointURL: 'URL du point final',
      hostedEndpoints: "Points d'extrémité hébergés",
      notSet: 'Non défini',
      penIcon: "Webhook avec l'icône du crayon",
      plusIcon: 'Webhook avec icône plus',
      registeredEndpoints: "Points d'extrémité enregistrés pour écouter et gérer les événements.",
      retrieveSecretError: 'Erreur inattendue : Impossible de récupérer le secret du webhook',
      saveEndpoint: 'Sauvegarder le point final',
      secret: 'Secret',
      set: 'Set (jeu de mots)',
      setupInfo:
        'Configurez votre point de terminaison webhook pour recevoir des événements en direct de Keyo ou en savoir plus sur les webhooks.',
      title: 'Crochets Web',
      trashIcon: 'Webhook avec icône de corbeille',
      url: 'URL',
      webhookCreation: "Création d'un webhook",
      webhookEdit: 'Modification du Webhook',
    },
    zendesk: {
      signingIn: 'Se connecter à Zendesk',
    },
  },
  pages: {
    notFoundOrNoAccess: {
      message: "Cette page n'existe pas ou il n'est pas possible d'y accéder.",
    },
    org: {
      billingAddressForm: {
        cityLabel: 'Ville',
        countryLabel: 'Pays',
        stateLabel: 'État',
        stateRegionLabel: 'État/Région',
        streetLabel: 'Rue',
        zipLabel: 'Zip',
      },
      businessProfileForm: {
        primaryContactFirstNameLabel: 'Contact principal (prénom)',
        primaryContactLastNameLabel: 'Contact principal (nom de famille)',
        primaryEmailLabel: 'Courriel principal',
      },
      devices: {
        deleteError: "Une erreur s'est produite lors de la suppression d'une photo de localisation",
        description: 'Voici les appareils de votre organisation.',
        deviceLocation: "Emplacement de l'appareil",
        deviceName: "Nom de l'appareil",
        generalInformation: 'Informations générales',
        location: 'Localisation',
        model: 'Modèle',
        photoAdded: "Ajout d'une photo de localisation",
        serialNumber: 'Numéro de série',
        title: 'Dispositifs',
        unexpectedError: "Erreur inattendue lors du chargement des données de l'appareil",
        uploadDescription:
          'Vous pouvez télécharger des photos de la zone du terminal pour montrer son emplacement.',
        uploadError: "Une erreur s'est produite lors du téléchargement de la photo du lieu",
        view: 'Voir',
      },
      generalForm: {
        cityRequired: 'La ville est requise',
        countryRequired: 'Le pays est requis',
        messages: {
          organizationUpdateError: "Échec de la mise à jour des informations sur l'organisation",
          organizationUpdateSuccess: "Mise à jour des informations sur l'organisation",
        },
        nameRequired: 'Le nom est obligatoire',
        stateRequired: "l'état est requis",
        streetRequired: 'La rue est nécessaire',
        zipRequired: 'Le code postal est requis',
      },
      members: {
        exportCSV: 'Exporter CSV',
        general: 'Général',
        members: 'Les membres',
        orgMembers: 'Voici les membres de votre organisation.',
        pause: 'Pause',
        pauseMember: 'Membre en pause',
        removeMember: 'Retirer le membre',
        renew: 'Renouveler',
        renewMember: "Renouveler l'adhésion",
        viewProfile: 'Voir le profil',
      },
      organizationLogo: {
        heading: "Logo de l'entreprise",
        logoRemoveSuccess: 'Le logo a été supprimé',
        logoUpdateSuccess: 'Logo mis à jour !',
        removeButton: 'Retirer',
        subtitle:
          "Il sera affiché sur la barre latérale gauche. Veuillez télécharger un fichier .jpg ou .png d'une taille maximale de 1 Mo.",
      },
      profile: {
        billingAddress: 'Adresse de facturation (si différente)',
        businessProfile: "Profil de l'entreprise",
        general: 'Général',
        legal: 'Juridique',
      },
      settings: {
        organizationProfile: "Profil de l'organisation",
        roles: 'Rôles',
      },
      uploadFile: {
        alt2: 'icône de la croix',
        text: 'Cliquez pour télécharger',
        uploadIcon: 'icône de téléchargement',
      },
    },
    pageNotFound: {
      message: 'Il manque quelque chose.',
    },
    sadAlt: 'triste',
  },
  utils: {
    validations: {
      confirm: {
        default: 'Veuillez confirmer votre {name}',
      },
      email: {
        default:
          "Veillez à fournir une adresse électronique correctement formatée (par exemple, exemple{'@'}exemple.com).",
      },
      emailOrPhone: {
        default: "Veuillez saisir l'adresse électronique ou le numéro de téléphone",
        formatPhone:
          "Veuillez saisir un numéro de téléphone valide au format '+11234567890'. Un maximum de 15 chiffres est autorisé.",
        validEmailOrPhone:
          'Veuillez saisir un numéro de téléphone valide au format "+11234567890" ou fournir une adresse électronique correctement formatée (par exemple, exemple{\'@\'}exemple.com).',
      },
      letters: {
        default: 'Ne peut contenir que des lettres',
      },
      match: {
        default: 'Doit correspondre au champ {name}.',
      },
      max: {
        default: 'La longueur maximale est de {length}',
      },
      mfaCode: {
        default: 'Code à 6 chiffres.',
        shouldBeArray: "Il devrait s'agir d'un tableau.",
      },
      min: {
        default: 'La longueur minimale est de {length}',
      },
      name: {
        default: "Seuls les lettres, le trait d'union, l'apostrophe et le point sont autorisés.",
      },
      password: {
        default: 'Veuillez saisir votre mot de passe',
        weakPassword: 'Le mot de passe est faible.',
      },
      phone: {
        default: 'Numéro non valide.',
        possiblePhone: 'Le format doit être international et commencer par +.',
      },
      required: {
        default: 'Ce champ est obligatoire',
      },
      url: {
        default: 'URL non valide.',
      },
    },
  },
}
