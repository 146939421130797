import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import type { I18nLang } from '@keyo/core/i18n'
import { fallbackLang, i18n, languagesAvailable } from '@keyo/core/i18n'
import { usePersonalStore } from '@/modules/account'
import { SELECTED_LOCALE } from '@/constants/localStorageKeys.ts'
import { updateApiHeaders } from '@/api'
import { FEATURE_FLAGS } from '@/constants/config.ts'

export default function useLanguage() {
  const personalStore = usePersonalStore()
  const { profile } = storeToRefs(personalStore)
  const { profileUpdate } = personalStore

  const selectedLang = computed<I18nLang>(() => {
    const selectedLanguage = languages.value.find(
      ({ locale }: I18nLang) => locale === i18n.global.locale.value,
    )
    return selectedLanguage || fallbackLang
  })

  const currentLocale = computed(() => i18n.global.locale.value as 'en' | 'es')

  const languages = computed(() =>
    languagesAvailable.filter(({ isBeta }) => FEATURE_FLAGS.value.ENABLE_BETA_LANGS || !isBeta),
  )

  const getLocaleFromAvailable = (locale: string): string => {
    const language = languages.value.find(({ locale: lang }) => lang === locale)
    return language?.locale ?? fallbackLang.locale
  }

  const updateLocale = (newLocale: string) => {
    const locale = getLocaleFromAvailable(newLocale)
    i18n.global.locale.value = locale
    localStorage.setItem(SELECTED_LOCALE, locale)
    document.documentElement.lang = locale
    updateApiHeaders({ 'Accept-Language': locale })
  }

  const saveLocaleInUserSettings = (locale: string) => {
    const localeFromAvailable = getLocaleFromAvailable(locale)
    profileUpdate({
      ui_settings: {
        ...profile.value.ui_settings,
        user_locale: localeFromAvailable,
      },
      language: localeFromAvailable,
    })
  }

  return {
    languages,
    currentLocale,
    selectedLang,
    updateLocale,
    saveLocaleInUserSettings,
  }
}
