import type {
  ChangeEmailBody,
  Profile,
  CodeBody,
  ProfilePhoto,
  PhoneSetBody,
  DeleteByMethodBody,
  ChangePhoneBody,
  VerifyMethodBody,
  EmailSetBody,
} from '@/modules/account/pinia'
import type { SendCodeBody } from '@/api/auth.ts'
import { prepareBody } from '@/api/auth.ts'
import { apiV3, apiV4 } from '@/api'

export default {
  profileGet: () => apiV3.get('users/profile/'),
  profileUpdate: (body: Partial<Profile>) => apiV3.patch('users/profile/', body),
  profileDelete: (body: DeleteByMethodBody) => {
    const { code, ...rest } = prepareBody<DeleteByMethodBody>(body)

    return apiV3.post('users/profile/delete/', {
      code: code.join(''),
      ...rest,
    })
  },
  profileSetPhoto: (photo: ProfilePhoto) => {
    const form = new FormData()
    form.append('photo', photo)
    return apiV3.patch('users/update-photo/', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  changePassword: (body: CodeBody) => {
    const { code, ...rest } = prepareBody<CodeBody>(body)
    return apiV3.patch('users/update-password/', {
      //TODO: adjust pin to output string instead of array or some another better approach
      code: code.join(''),
      ...rest,
    })
  },
  changeEmail: (body: ChangeEmailBody) => {
    const { code_from_old_email, code_from_new_email, ...rest } = body
    return apiV3.patch('users/update-email/', {
      code_from_old_email: code_from_old_email?.join(''),
      code_from_new_email: code_from_new_email?.join(''),
      ...rest,
    })
  },
  validateChangeEmail: (body: ChangeEmailBody) => {
    const { code_from_old_email, code_from_new_email, ...rest } = body
    return apiV3.post('users/validate-email/', {
      code_from_old_email: code_from_old_email?.join(''),
      code_from_new_email: code_from_new_email?.join(''),
      ...rest,
    })
  },

  validateChangePhone: (body: ChangePhoneBody) => {
    const { code_from_old_phone, code_from_new_phone, ...rest } = body
    return apiV3.post('users/validate-phone/', {
      code_from_old_phone: code_from_old_phone?.join(''),
      code_from_new_phone: code_from_new_phone?.join(''),
      ...rest,
    })
  },

  changePhone: (body: ChangePhoneBody) => {
    const { code_from_old_phone, code_from_new_phone, ...rest } = prepareBody<ChangePhoneBody>(body)

    return apiV3.patch('users/update-phone/', {
      code_from_old_phone: code_from_old_phone?.join(''),
      code_from_new_phone: code_from_new_phone?.join(''),
      ...rest,
    })
  },

  palmDelete(body: DeleteByMethodBody) {
    const { code, ...rest } = prepareBody<DeleteByMethodBody>(body)

    return apiV3.post('users/biometric-data/delete/', {
      code: code.join(''),
      ...rest,
    })
  },
  setEmail: (body: EmailSetBody) => {
    const { code, ...rest } = prepareBody<EmailSetBody>(body)

    return apiV3.patch('users/set-email/', {
      code: code.join(''),
      ...rest,
    })
  },
  setPhone: (body: PhoneSetBody) => {
    const { code, ...rest } = prepareBody<PhoneSetBody>(body)

    return apiV3.patch('users/set-phone/', {
      code: code.join(''),
      ...rest,
    })
  },
  verifyMethod: (body: VerifyMethodBody) => {
    const { code, ...rest } = prepareBody<VerifyMethodBody>(body)

    return apiV3.post('users/verify-method/', {
      code: code.join(''),
      ...rest,
    })
  },
  mfaCodeRequest: (body: SendCodeBody) => apiV4.post('auth/send-code/', body),
}
