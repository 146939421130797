<script lang="ts">
import { reactive } from 'vue'
import { Btn, TextField, PhoneField, Dropdown } from '@keyo/ui'

import { useOrganizationsStore } from '@/store/organizations'

import useModal from '@/composables/useModal'

import { useCountries } from '@/utils/countriesList'
import toast from '@/libs/toast'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import { phone, required, external } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import { i18n } from '@keyo/core/i18n'
import type { AxiosError } from 'axios'

interface Form {
  name: string
  dba: string
  tin: string
  shipping_address: {
    country: string
    state: string
    city: string
    zip_code: string
    street: string
  }
  business_phone: string
}

type Errors = { [key: string]: string } & Partial<Form & { non_field_errors: string }>

export default {
  name: 'CreateOrganization',
  components: { NonFieldErrors, Btn, TextField, Dropdown, PhoneField },
  setup() {
    const modal = useModal()
    const { create } = useOrganizationsStore()

    const { handleResponseException } = useFormHelpers()

    const form = reactive<Form>({
      name: '',
      dba: '',
      tin: '',
      shipping_address: {
        country: '',
        state: '',
        city: '',
        zip_code: '',
        street: '',
      },
      business_phone: '',
    })

    const externalResults = reactive<Errors>({})
    const rules = {
      name: [required()],
      dba: [external],
      tin: [external],
      shipping_address: {
        country: [required()],
        state: [required()],
        city: [required()],
        zip_code: [required()],
        street: [required()],
      },
      business_phone: [phone()],
    }
    const v$ = useVuelidate(rules, form, {
      $externalResults: externalResults,
      $rewardEarly: true,
      $autoDirty: true,
    })

    const { countriesOptions } = useCountries()

    return {
      create,
      modal,
      form,
      countriesOptions,
      v$,
      externalResults,
      handleResponseException,
    }
  },
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    async submitForm() {
      if (this.isSubmitting) return
      this.isSubmitting = true
      this.v$.$clearExternalResults()

      await this.v$.$validate()
      if (this.v$.$error) {
        this.isSubmitting = false
        return
      }
      try {
        await this.create(this.form)
        this.modal.hide()
        toast.show(() => i18n.global.t('components.modals.createOrganization.created'), 'success')
      } catch (error) {
        const { response } = error as AxiosError
        if (response?.status === 403) {
          toast.show(() => i18n.global.t('common.noPermission'), 'error')
          this.modal.hide()
          return
        } else {
          this.handleResponseException(response, this.externalResults)
        }
        this.isSubmitting = false
        toast.show(
          () => i18n.global.t('components.modals.createOrganization.failedCreated'),
          'error',
        )
      }
    },
  },
}
</script>

<template>
  <div class="create-organization">
    <h2 class="title text-heading-xl">
      {{ $t('components.modals.createOrganization.title') }}
    </h2>
    <p class="text text-body-m">
      {{ $t('components.modals.createOrganization.subtitle') }}
    </p>
    <form @submit.prevent="submitForm">
      <div class="input-group">
        <TextField
          id="name-m"
          v-model="form.name"
          :label="`${$t('common.officialName')}*`"
          :state="v$.name.$error ? 'error' : ''"
          :tip="v$.name.$errors?.[0]?.$message"
        />
        <TextField
          id="dba-m"
          v-model="form.dba"
          :label="$t('common.dba')"
          :state="v$.dba.$error ? 'error' : ''"
          :tip="v$.dba.$errors?.[0]?.$message"
        />
      </div>
      <div class="input-group">
        <TextField
          id="tin-m"
          v-model="form.tin"
          :label="$t('common.tinIdOrRegistration')"
          :state="v$.tin.$error ? 'error' : ''"
          :tip="v$.tin.$errors?.[0]?.$message"
        />
        <PhoneField
          id="business-phone-m"
          v-model="form.business_phone"
          :label="$t('common.phoneNumber')"
          :state="v$.business_phone.$error ? 'error' : ''"
          :tip="v$.business_phone.$errors?.[0]?.$message"
        />
      </div>
      <div class="input-group">
        <Dropdown
          id="country-m"
          v-model="form.shipping_address.country"
          searchable
          :options="countriesOptions"
          :label="`${$t('common.country')}*`"
          :state="v$.shipping_address.country.$error ? 'error' : ''"
          :tip="v$.shipping_address.country.$errors?.[0]?.$message"
        />
        <TextField
          id="state-m"
          v-model="form.shipping_address.state"
          :label="
            form.shipping_address.country === 'US'
              ? `${$t('common.stateUs')}*`
              : `${$t('common.stateRegion')}*`
          "
          :state="v$.shipping_address.state?.$error ? 'error' : ''"
          :tip="v$.shipping_address.state.$errors?.[0]?.$message"
          capitalize
        />
      </div>
      <div class="input-group">
        <TextField
          id="city-m"
          v-model="form.shipping_address.city"
          :label="`${$t('common.city')}*`"
          :state="v$.shipping_address.city.$error ? 'error' : ''"
          :tip="v$.shipping_address.city.$errors?.[0]?.$message"
          capitalize
        />
        <TextField
          id="zip-m"
          v-model="form.shipping_address.zip_code"
          :label="`${$t('common.zip')}*`"
          :state="v$.shipping_address.zip_code.$error ? 'error' : ''"
          :tip="v$.shipping_address.zip_code.$errors?.[0]?.$message"
        />
      </div>
      <TextField
        id="street-m"
        v-model="form.shipping_address.street"
        :label="`${$t('common.street')}*`"
        :state="v$.shipping_address.street.$error ? 'error' : ''"
        :tip="v$.shipping_address.street.$errors?.[0]?.$message"
      />

      <NonFieldErrors :errors="externalResults.non_field_errors" />

      <div class="buttons">
        <Btn type="submit" :loading="isSubmitting">
          {{ $t('buttons.create') }}
        </Btn>
        <Btn kind="minimal" @click="modal.hide">{{ $t('buttons.cancel') }}</Btn>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.create-organization {
  width: 100%;
  max-width: 50rem;
}

form {
  display: grid;
  grid-row-gap: 1.5rem;

  :deep(.dropdown) {
    --dropdown-menu-height: 15rem;

    background-color: var(--color-grey-200);

    &:hover {
      border: 1px solid var(--color-grey-400);
    }
  }
}

.title {
  margin-bottom: 0.8rem;
}
.text {
  margin-bottom: 1.5rem;
}
.input-group {
  display: grid;
  grid-row-gap: 1.5rem;
  grid-template-columns: 1fr;
  grid-column-gap: 1.75rem;

  @media screen and (min-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }
}
.buttons {
  margin: 1.25rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.75rem;
  grid-row-gap: 1.5rem;

  @media screen and (min-width: $mobile) {
    grid-template-columns: 12.5rem 12.5rem;
  }
}
</style>
