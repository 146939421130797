import type { CardInput, CardResult, CardResults } from '@/modules/wallet/model'
import type {
  TransactionInput,
  TransactionResult,
  TransactionResults,
} from '@/components/Transaction/model'
import { DEFAULT_LIMIT } from '@/constants/pagination'
import type { Pagination } from '@/types/pagination'
import { apiV3 } from '@/api'

type OrgMemberIds = {
  orgId: number
  memberId: number
}

type CardId = {
  cardId: string
}

type CardBody = {
  card: CardInput
}

type PartialCardBody = {
  card: Partial<CardInput>
}

type TransactionBody = {
  transaction: TransactionInput
}

export default {
  listCards({ orgId, memberId }: OrgMemberIds) {
    return apiV3.get<CardResults>(`organizations/${orgId}/members/${memberId}/cards/`)
  },
  listTransactionsByCard({
    orgId,
    memberId,
    cardId,
    limit,
    offset,
  }: OrgMemberIds & CardId & Pagination) {
    return apiV3.get<TransactionResults>(
      `organizations/${orgId}/members/${memberId}/cards/${cardId}/transactions/?limit=${limit}&offset=${offset}`,
    )
  },
  listTransactionsByOrg({ orgId }: OrgMemberIds) {
    return apiV3.get<TransactionResults>(`organizations/${orgId}/transactions/`)
  },
  listTransactionsUser({ limit, offset }: Pagination = { limit: DEFAULT_LIMIT, offset: 0 }) {
    const url = `transactions/?limit=${limit}&offset=${offset}`
    return apiV3.get<TransactionResults>(url)
  },
  updateCard({ orgId, memberId, cardId, card }: OrgMemberIds & CardId & PartialCardBody) {
    return apiV3.patch<CardResult>(
      `organizations/${orgId}/members/${memberId}/cards/${cardId}/`,
      card,
    )
  },
  deleteCard({ orgId, memberId, cardId }: OrgMemberIds & CardId) {
    return apiV3.delete<null>(`organizations/${orgId}/members/${memberId}/cards/${cardId}/`)
  },

  // TODO testing methods, not needed in Dashboard, remove
  createCard({ orgId, memberId, card }: OrgMemberIds & CardBody) {
    return apiV3.post<CardResult>(`organizations/${orgId}/members/${memberId}/cards/`, card)
  },
  createTransaction({
    orgId,
    memberId,
    cardId,
    transaction,
  }: OrgMemberIds & CardId & TransactionBody) {
    return apiV3.post<TransactionResult>(
      `organizations/${orgId}/members/${memberId}/cards/${cardId}/transactions/`,
      transaction,
    )
  },
}
