<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import toast from '@/libs/toast'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { whenever } from '@vueuse/core'
import type { AxiosError } from 'axios'

import { DialogBtn, Avatar } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import DeviceOrganizationData from '../components/DeviceOrganizationData.vue'

import useModal from '@/composables/useModal'

import { useDevicesStore } from '@/store'
import { usePersonalStore } from '../pinia'
import { getDeviceProfileExposedData } from '@/api/organization'

import useFormHelpers from '@/composables/useFormHelpers'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@keyo/core/validations'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'

import { I18nT, useI18n } from 'vue-i18n'

type Form = {
  organization_id: string
  device_id: string
}

const props = defineProps({
  organizationId: {
    type: String,
    required: true,
  },
  deviceId: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()
const personalStore = usePersonalStore()
const devicesStore = useDevicesStore()
const { startEnroll } = devicesStore
const { someName, isProfileSet } = storeToRefs(personalStore)
const modal = useModal()
const router = useRouter()
const { handleResponseException } = useFormHelpers()

const deviceOrgData = ref()
const isLoaded = ref(false)
const isSubmitting = ref(false)
const followWaveInstructions = ref(false)

const form = reactive({
  organization_id: '',
  device_id: '',
})

type Errors = { [key: string]: string } & Partial<Form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})

const rules = {
  organization_id: [required()],
  device_id: [required()],
}

const v$ = useVuelidate(rules, form, { $externalResults: externalResults })

const closeModal = () => {
  router.replace({ name: 'personal' })
  modal.hide()
}

const showLegalBanner = computed(
  () =>
    deviceOrgData.value?.organization.terms_of_use ||
    deviceOrgData.value?.organization.privacy_policy,
)

const showQRCodeError = () => {
  closeModal()
  toast.show(() => t('modules.personal.modals.qrScanner.invalidQrMessage'), 'error')
}

const loadModal = async () => {
  form.organization_id = props.organizationId as string
  form.device_id = props.deviceId as string

  if (!form.organization_id || !form.device_id) {
    showQRCodeError()
    return
  }

  try {
    const { data } = await getDeviceProfileExposedData(form.organization_id, form.device_id)
    deviceOrgData.value = data
    isLoaded.value = true
  } catch (error) {
    showQRCodeError()
  }
}

const submit = async () => {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    await v$.value.$validate()
    if (v$.value.$error) {
      isSubmitting.value = false
      return
    }

    await startEnroll(form.organization_id, form.device_id)
    followWaveInstructions.value = true
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

whenever(
  () => isProfileSet.value,
  () => {
    loadModal()
  },
  { immediate: true },
)
</script>

<template>
  <ModalCard
    v-if="!followWaveInstructions"
    tag="form"
    class="account-biometric-confirm-enroll"
    :with-mobile-header-style="false"
    @submit.prevent="submit"
  >
    <Avatar
      class="org-avatar"
      :class="{
        skeleton: !deviceOrgData,
      }"
      :src="deviceOrgData?.organization.logo"
      :initials="deviceOrgData?.organization.name?.[0] || ''"
    />

    <div class="org-info">
      <h1 class="text-heading-m">{{ t('common.hiName', { name: someName }) }}</h1>

      <p class="text-body-m org-info-desc">
        {{ t('modules.account.modals.accountBiometricConfirmEnroll.startEnrollmentQuestion') }}
      </p>
    </div>

    <DeviceOrganizationData :data="deviceOrgData" />

    <NonFieldErrors
      v-if="v$.device_id.$error"
      :errors="t('modules.account.modals.accountBiometricConfirmEnroll.deviceMissingError')"
      class="error"
    />
    <NonFieldErrors
      v-if="v$.organization_id.$error"
      :errors="t('modules.account.modals.accountBiometricConfirmEnroll.organizationMissingError')"
      class="error"
    />
    <NonFieldErrors
      v-if="externalResults.non_field_errors"
      :errors="externalResults.non_field_errors"
      class="error"
    />
    <template #buttons>
      <div v-if="showLegalBanner" class="org-legal">
        <I18nT keypath="modules.account.modals.accountBiometricConfirmEnroll.orgLegalMessage">
          <template #legal>
            <a
              v-if="deviceOrgData.organization.terms_of_use"
              :href="deviceOrgData.organization.terms_of_use"
              target="_blank"
            >
              {{ $t('common.termsOfUse') }}
            </a>
            <template
              v-if="
                deviceOrgData.organization.terms_of_use && deviceOrgData.organization.privacy_policy
              "
            >
              {{ ` ${$t('common.and')} ` }}
            </template>
            <a
              v-if="deviceOrgData.organization.privacy_policy"
              :href="deviceOrgData.organization.privacy_policy"
              target="_blank"
            >
              {{ $t('common.privacyPolicy') }}
            </a>
          </template>
        </I18nT>
      </div>

      <div class="buttons">
        <DialogBtn kind="secondary" :disabled="isSubmitting" @click="closeModal">
          {{ t('buttons.cancel') }}
        </DialogBtn>
        <DialogBtn :disabled="v$.$invalid" :loading="isSubmitting" type="submit">
          {{ t('buttons.continue') }}
        </DialogBtn>
      </div>
    </template>
  </ModalCard>

  <ModalCard
    v-else
    class="account-biometric-confirm-enroll"
    :heading="t('modules.account.modals.accountBiometricConfirmEnroll.almostDone')"
    :description="t('modules.account.modals.accountBiometricConfirmEnroll.followInstructions')"
    :with-mobile-header-style="false"
  >
    <template #header>
      <video playsinline autoplay loop muted poster="../assets/videos/white-thumbnail.png">
        <source src="../assets/videos/bio-review-v0.2.webm" type="video/webm" />
        <source src="../assets/videos/bio-review-v0.2.mp4" type="video/mp4" />
      </video>
    </template>
    <template #buttons>
      <DialogBtn @click="closeModal">{{ t('buttons.gotIt') }}</DialogBtn>
    </template>
  </ModalCard>
</template>

<style scoped lang="scss">
.account-biometric-confirm-enroll {
  &.dialog-card.modal {
    display: grid;
    align-items: center;
    grid-template-rows: unset;
  }

  video,
  :deep(.dialog-icon) {
    aspect-ratio: 16 / 10;
    margin-bottom: 1.5rem;
    border-radius: 1.25rem;
    width: 100%;
    height: unset;
    object-fit: cover;
  }

  .org-avatar {
    width: 10rem;
    height: 10rem;
    border: 1px solid var(--color-grey-400);
    border-radius: 2rem;
    margin: 0 auto 1rem;

    :deep(span) {
      font: var(--text-heading-xxl);
    }
  }

  // TODO: Look into DialogCard's .dialog-content to refactor styles or html/slot structure to avoid grid and gap overcomplicating things and make it more flexible
  .org-info {
    display: grid;
    gap: 0.75rem;
  }

  .org-info-desc {
    color: var(--color-grey-700);
  }

  :deep(.btn-container) {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .btn {
      flex: auto;
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }

  .org-legal {
    border-radius: 1rem;
    background-color: var(--color-grey-100);
    padding: 0.75rem;
    text-align: center;
    font: var(--text-body-xs);

    a {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $mobile) {
  .account-biometric-confirm-enroll.dialog-card.modal {
    :deep(.btn-container) {
      flex-direction: column;
    }
    .buttons {
      margin-top: auto;
      flex-direction: column-reverse;
    }

    .org-avatar {
      width: 9rem;
      height: 9rem;
    }
  }
}
</style>
