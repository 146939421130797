import type { SignInMethod } from '@/api/auth.ts'
import { i18n } from '@keyo/core/i18n'
import type { FieldError } from '@keyo/core/validations'
import type { Stringable } from '@keyo/core'
import type { ErrorObject } from '@vuelidate/core'

export const getSignInMethodFromType = (type: string): SignInMethod =>
  type.includes('@') ? 'email' : 'phone'

export const getPasswordFieldError = ({
  $error,
  $errors,
}: {
  $error: boolean
  $errors: ErrorObject[]
}) => {
  if (!$error) {
    return ''
  }

  const externalError = $errors?.[0]?.$message as Stringable
  const parsedExternalError = externalError as FieldError

  // 2051: Ensure this field has at least 9 characters.
  if (parsedExternalError.code === 2051) {
    return i18n.global.t('errors.weakPassword')
  }

  return externalError
}
