<script setup>
import { DialogBtn } from '@keyo/ui'
import useModal from '@/composables/useModal'
import { useRouter } from 'vue-router'
import ModalCardSuccess from '@/components/modals/components/ModalCardSuccess.vue'

const modal = useModal()
const router = useRouter()

const goToCardList = () => {
  modal.hide()
  router.replace({ name: 'personal.wallet' })
}
</script>

<template>
  <ModalCardSuccess :heading="$t('common.allDone')" :description="$t('common.allChangesSaved')">
    <template #buttons>
      <DialogBtn @click="goToCardList">{{ $t('buttons.gotIt') }}</DialogBtn>
    </template>
  </ModalCardSuccess>
</template>
