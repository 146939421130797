<script>
import { Btn } from '@keyo/ui'
import IconBox from '@/components/IconBox'

import toast from '@/libs/toast'

import { useModal } from '@/composables'
import { useWebhooksStore } from '../pinia'
import { i18nUtils } from '@keyo/core/i18n'

export default {
  components: { Btn, IconBox },
  props: {
    webhookId: [String, Number],
  },
  setup() {
    return {
      modal: useModal(),
      toast: toast.show,
      webhooks: useWebhooksStore(),
    }
  },

  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    async submitForm() {
      try {
        if (this.isSubmitting) return
        this.isSubmitting = true
        await this.webhooks.disable(this.$route.params.id, this.webhookId)
        this.modal.hide()
      } catch (e) {
        this.toast(i18nUtils.errorSomethingBroken, 'error')
        this.isSubmitting = false
      }
    },
  },
}
</script>

<template>
  <form class="webhook-create__container" @submit.prevent="submitForm">
    <IconBox>
      <img src="../assets/icons/webhook-disable.svg" :alt="$t('modules.webhooks.disableIcon')" />
    </IconBox>
    <h1 class="webhook-create__title">{{ $t('modules.webhooks.disableWebhook') }}</h1>
    <p class="webhook-create__text">
      {{ $t('modules.webhooks.disableWebhookInfo') }}
    </p>
    <div class="webhook-create__buttons">
      <Btn type="submit" :is-processing="isSubmitting">{{ $t('modules.webhooks.disable') }}</Btn>
      <Btn kind="minimal" @click="modal.hide">{{ $t('buttons.cancel') }}</Btn>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.webhook-create__container {
  width: 100%;
  max-width: 620px;
}

.webhook-create__title {
  font-weight: 700;
  font-size: 36px;

  margin: 0 0 20px 0;
}

.webhook-create__text {
  font-size: 16px;

  margin: 0 0 18px 0;
}

.webhook-create__buttons {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 11px;
}
</style>
